<template>
  <div class="feed-wrapper view-feed" ref="feedWrapper" @scroll="handleScroll">
    <FeedItem
      v-for="item in feedItems"
      :key="item.id"
      :item="item"
      @like="likeItem"
      @bookmark="bookmarkItem"
      @share="shareItem"
    />

    <div v-if="loading" class="has-text-centered">
      <div class="loader is-loading"></div>
    </div>

    <div v-if="error" class="has-text-centered mt-4">
      <p class="has-text-danger mb-3">{{ error }}</p>
      <button class="button is-primary" @click="retryLoad">Try Again</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { feedService } from '@/services/api/FeedService';
import log from 'loglevel';
import FeedItem from '@/components/ListSummary.vue';

const feedItems = ref([]);
const loading = ref(false);
const error = ref(null);
const page = ref(1);
const hasMore = ref(true);
const feedWrapper = ref(null);

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const loadMoreItems = async () => {

  log.trace('loadMoreItems started')
  if (loading.value || !hasMore.value) return;

  loading.value = true;
  error.value = null;

  try {
    const response = await feedService.fetch(page.value);
    const newItems = response.value || [];
    
    if (newItems.length === 0) {
      hasMore.value = false;
    } else {
      feedItems.value.push(...newItems);
      page.value++;
    }
  } catch (err) {
    log.error('Error fetching feed items:', err);
    error.value = 'Unable to load feed items. Please try again.';
  } finally {
    loading.value = false;
  }
};

const handleScroll = () => {
  if (!feedWrapper.value) return;

  const { scrollTop, scrollHeight, clientHeight } = feedWrapper.value;
  log.debug('Scroll position:', scrollTop + clientHeight, 'of', scrollHeight);
  if (scrollTop + clientHeight >= scrollHeight - 200 && !loading.value && hasMore.value) {
    log.trace('Loading more items...');
    loadMoreItems();
  }
};

const retryLoad = () => {
  error.value = null;
  loadMoreItems();
};

onMounted(() => {
  loadMoreItems();
});

const likeItem = (item) => {
  // Implement like functionality
  log.debug('Like item:', item.id);
};

const bookmarkItem = (item) => {
  // Implement bookmark functionality
  log.debug('Bookmark item:', item.id);
};

const shareItem = (item) => {
  // Implement share functionality
  log.debug('Share item:', item.id);
};

</script>

<style scoped>
.feed-wrapper {
  height: calc(100vh - 60px);
  /* Adjust based on your header height */
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  /* Ensure the content is still scrollable */
  overflow-y: scroll;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3273dc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
