import BaseApiService from './BaseApiService';
import log from 'loglevel';

class MessagesService extends BaseApiService {
  constructor() {
    super();
    log.setLevel(process.env.VUE_APP_LOG_LEVEL || 'info');
  }
  
  async fetch() {
    try {
      log.debug('Fetching messages');
      const response = await this.api.get('/messages');
      return response.data;
    } catch (error) {
      log.error('Error fetching messages:', error);
      throw error;
    }
  }
}

export const messagesService = new MessagesService();
export const { fetch } = messagesService;