<template>
    <div class="modal" :class="{ 'is-active': isActive }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-header-title">{{ isEditing ? 'Edit Link' : 'Add Link' }}</h3>
                    <button class="delete" aria-label="close" @click="close"></button>
                </div>
                <div class="card-content">
                    <div class="field">
                        <label class="label">URL</label>
                        <div class="control has-icons-right">
                            <input class="input" type="url" v-model="link.url" placeholder="https://example.com">
                            <button class="button is-small is-info" @click="fetchMetadata"
                                :disabled="!link.url || isLoading">
                                <span class="icon">
                                    <i class="fas"
                                        :class="{ 'fa-sync-alt': !isLoading, 'fa-spinner fa-spin': isLoading }"></i>
                                </span>
                                <span>{{ isLoading ? 'Fetching...' : 'Fetch' }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="field image-preview-container" v-if="link.iconUrl || link.imageUrl">
                        <div class="image-preview" v-if="link.iconUrl">
                            <h4 class="image-title">Icon <button class="button remove is-danger" @click="removeIcon">X</button></h4>
                            <img :src="link.iconUrl" alt="Link icon" class="link-icon-image">
                            <input type="hidden" v-model="link.iconUrl">
                        </div>
                        <div class="image-preview" v-if="link.imageUrl">
                            <h4 class="image-title">Image <button class="button remove is-danger" @click="removeImage">X</button></h4>
                            <img :src="link.imageUrl" alt="Link preview" class="link-preview-image">
                            <input type="hidden" v-model="link.imageUrl">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Title</label>
                        <div class="control">
                            <input class="input" type="text" v-model="link.title" placeholder="Enter title">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Description</label>
                        <div class="control">
                            <textarea class="textarea" v-model="link.description"
                                placeholder="Enter description"></textarea>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Topics</label>
                        <div class="control">
                            <input class="input" type="text" v-model="topicsInput"
                                placeholder="Enter topics separated by commas">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button class="button" @click="close" :disabled="isSubmitting">Cancel</button>
                    <button class="button is-primary" @click="submitLink" :disabled="isSubmitting">
                        <span class="icon" v-if="isSubmitting">
                            <i class="fas fa-spinner fa-spin"></i>
                        </span>
                        <span>{{ isSubmitting ? (isEditing ? 'Updating...' : 'Adding...') : (isEditing ? 'Update' :
                            'Add') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch, onBeforeUnmount } from 'vue';
import { addLink as apiAddLink, fetchUrlMetadata, updateLink } from '@/services/api';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const props = defineProps({
    isActive: Boolean,
    userkey: String,
    listkey: String,
    editingLink: Object, // Existing link object when editing
});

const emit = defineEmits(['close', 'linkAdded', 'linkUpdated']);

const link = reactive({
    url: 'https://',
    title: '',
    description: '',
    topics: [],
    itemkey: '', // Add itemkey for editing
    iconUrl: '',
    imageUrl: ''
});

const topicsInput = ref('');
const isEditing = ref(false);

// Populate form when editing an existing link
const populateForm = () => {
    if (props.editingLink) {
        isEditing.value = true;
        Object.assign(link, props.editingLink);
        // Check if topics exist and is an array before joining
        topicsInput.value = Array.isArray(props.editingLink.topics)
            ? props.editingLink.topics.join(', ')
            : '';
    } else {
        isEditing.value = false;
        resetForm();
    }
};

// Watch for changes in the editingLink prop
watch(() => props.editingLink, (newVal) => {
    if (newVal) {
        populateForm();
    }
});

// Watch for changes in the isActive prop
watch(() => props.isActive, (newVal) => {
    if (newVal) {
        addKeyListener();
        populateForm(); // Populate the form when the modal becomes active
    } else {
        removeKeyListener();
    }
});

const isLoading = ref(false);

const close = () => {
    emit('close');
    removeKeyListener();
    resetForm(); // Reset the form when closing the modal
};

const isSubmitting = ref(false);

const removeImage = () => {
  link.imageUrl = undefined;
};

const removeIcon = () => {
  link.iconUrl = undefined;
};

const submitLink = async () => {
    link.topics = topicsInput.value.split(',').map(tag => tag.trim()).filter(tag => tag !== '');

    isSubmitting.value = true;
    try {
        let result;
        if (isEditing.value) {
            result = await updateExistingLink();
        } else {
            result = await addNewLink();
        }
        log.debug("Result from submitting link.", result);
        if (result.success) {
            log.debug(`Link ${isEditing.value ? 'updated' : 'added'} successfully`);
            log.trace('Resetting the form');
            resetForm();
            log.debug(`Emitting ${isEditing.value ? 'linkUpdated' : 'linkAdded'} event`);
            emit(isEditing.value ? 'linkUpdated' : 'linkAdded', result.data);
            log.debug('Emitting close event');
            emit('close');
        } else {
            throw new Error(`Failed to ${isEditing.value ? 'update' : 'add'} link`);
        }
    } catch (error) {
        log.error(`Error ${isEditing.value ? 'updating' : 'adding'} link:`, error);
        // Handle error (e.g., show error message to user)
    } finally {
        isSubmitting.value = false;
    }
};

const addNewLink = async () => {
    try {
        const response = await apiAddLink(props.userkey, props.listkey, { ...link });
        log.debug('API response:', response);
        // If the response is 204 (No Content), it's considered a success
        return { success: true, data: response };
    } catch (error) {
        log.error('Error adding link:', error);
        throw new Error('Failed to add link');
    }
};

const updateExistingLink = async () => {
    try {
        log.debug('Updating link: ', props.userkey, props.listkey, link.itemkey);
        const response = await updateLink(props.userkey, props.listkey, link.itemkey, { ...link });
        log.debug('API response:', response);
        // If the response is 204 (No Content), it's considered a success
        return { success: true, data: response };
    } catch (error) {
        log.error('Error updating link:', error);
        throw new Error('Failed to update link');
    }
};

const resetForm = () => {
    link.url = 'https://';
    link.title = '';
    link.description = '';
    link.topics = '';
    link.iconUrl = '';
    link.imageUrl = '';
};

const fetchMetadata = async () => {

    isLoading.value = true;

    if (!link.url) return;

    try {
        const encodedUrl = encodeURIComponent(link.url);
        const response = await fetchUrlMetadata(encodedUrl);
        log.debug("Response for metadata", response);
        log.debug("Response for metadata - title", response.title);
        if (response) {
            // Use Object.assign to trigger reactivity
            Object.assign(link, {
                title: response.title || '',
                description: response.description || '',
                iconUrl: response.iconUrl || '', // Add imageUrl from metadata
                imageUrl: response.imageUrl || '' // Add imageUrl from metadata
            });
        }
    } catch (error) {
        console.error('Error fetching metadata:', error);
        // You might want to show an error message to the user here
    }
    isLoading.value = false;
};

const addKeyListener = () => {
    document.addEventListener('keydown', handleKeyDown);
};

const removeKeyListener = () => {
    document.removeEventListener('keydown', handleKeyDown);
};

const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
        close();
    }
};

onBeforeUnmount(() => {
    removeKeyListener();
});
</script>

<style scoped>
.card-header {
    align-items: center;
    padding-right: 20px;
}

.button .icon {
    position: relative;
}

.card-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.card-footer .button {
    margin-left: 0.5rem;
}

.control.has-icons-right {
    display: flex;
    align-items: center;
}

.control.has-icons-right .button {
    margin-left: 0.5rem;
}

.image-preview-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 45%;
}

.image-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.link-icon-image {
  max-width: 40px;
  max-height: 40px;
  object-fit: contain;
}

.link-preview-image {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
  border: solid 1px silver;
}

.image-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.image-title {
  margin-bottom: 0;
}

.button.remove {
  padding: 0.5em;
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
}
</style>