<template>
    <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
        <div class="loader-label">Loading contents ... please wait!</div>
    </div>
    <div v-else-if="error" class="error-message">
        <span class="icon-text">
            <span class="icon is-large">
                <i class="fas fa-exclamation-triangle fa-2x"></i>
            </span>
            <span>{{ error }}</span>
        </span>
        <div class="mt-4">
            <button @click="fetchList" class="button is-primary">
                <span class="icon">
                    <i class="fas fa-sync-alt"></i>
                </span>
                <span>Try Again</span>
            </button>
        </div>
    </div>
    <div v-else-if="list" class="list-view">

        <div class="list-info">
            <div class="title-container">
                <h1 class="title">
                    <FeatherIcon icon="inbox" size="32" /> {{ list.title }} ({{ totalItemCount }})
                </h1>
                <div class="title-actions">
                    <div class="refresh-button" @click="refreshListContents">
                        <FeatherIcon icon="refresh-cw" class="refresh-icon"
                            :class="{ 'is-refreshing': isRefreshingContents }" />
                        <span class="hide-on-mobile">Refresh</span>
                    </div>
                    <button v-if="canEdit" @click="openEditListDetailsModal" class="options-button button is-primary">
                        <i data-feather="more-vertical"></i>
                    </button>
                </div>
            </div>

            <div>
                <div class="list-contents-container">
                    <div v-if="isRefreshingContents" class="loader-container">
                        <div class="loader"></div>
                    </div>
                    <div v-else-if="list?.items?.length === 0" class="empty-list-message">
                        <div class="message">
                            <FeatherIcon icon="inbox" size="48" />
                            <h3>Your inbox is empty</h3>
                        </div>
                    </div>
                    <div v-else class="list-items" :class="displayFormat">
                        <template v-for="(items, date) in paginatedGroupedItems" :key="date">
                            <div class="date-group-header">
                                {{ formatDateHeader(date) }}
                            </div>
                            <div class="date-group-items">
                                <InboxItem 
                                    v-for="item in items" 
                                    :key="item.itemkey" 
                                    :item="item"
                                    @edit="openEditModal" 
                                    @delete="openDeleteConfirmation" 
                                />
                            </div>
                        </template>
                    </div>
                    
                    <div v-if="totalPages > 1" class="pagination-controls">
                        <button 
                            class="button" 
                            :disabled="currentPage === 1"
                            @click="currentPage--">
                            Previous
                        </button>
                        <span class="pagination-info">Page {{ currentPage }} of {{ totalPages }}</span>
                        <button 
                            class="button" 
                            :disabled="currentPage === totalPages"
                            @click="currentPage++">
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmDeleteModal :isActive="showConfirmModal" :isDeleting="isDeletingItem" @confirm="confirmDelete"
            @cancel="cancelDelete" />

    </div>
</template>

<script setup>
import { ref, onMounted, onUpdated, computed } from 'vue';
import InboxItem from '@/components/InboxItem.vue';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
import feather from 'feather-icons';
import log from 'loglevel';
import { inboxService } from '@/services/api/InboxService';
import { format, parseISO } from 'date-fns';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const list = ref(null);
const error = ref(null);
const isRefreshingContents = ref(false);
const showConfirmModal = ref(false);
const itemToDelete = ref(null);
const isDeletingItem = ref(false);
const openMenuItemKey = ref(null); // New ref to track the open menu
const isLoading = ref(true);

const ITEMS_PER_PAGE = 12;
const currentPage = ref(1);

const totalItemCount = computed(() => {
    if (!list.value || !list.value.items) return 0;

    return list.value.items.reduce((total, item) => {
        if (item.itemType === 'link') {
            return total + 1;
        } else if (item.itemType === 'list' && item.totalItems) {
            return total + item.totalItems;
        }
        return total;
    }, 0);
});

const groupedItems = computed(() => {
    if (!list.value?.items) return [];
    
    // Sort items by addedOn date (newest first)
    const sortedItems = [...list.value.items].sort((a, b) => 
        new Date(b.addedOn) - new Date(a.addedOn)
    );
    
    // Group items by date
    return sortedItems.reduce((groups, item) => {
        const date = format(parseISO(item.addedOn), 'yyyy-MM-dd');
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(item);
        return groups;
    }, {});
});

const paginatedGroupedItems = computed(() => {
    const allDates = Object.keys(groupedItems.value);
    let itemCount = 0;
    let result = {};
    
    // Calculate start and end dates for current page
    let currentItemCount = 0;
    let startIndex = 0;
    
    // Find the starting date index for current page
    for (let i = 0; i < allDates.length; i++) {
        const date = allDates[i];
        if (currentItemCount + groupedItems.value[date].length <= (currentPage.value - 1) * ITEMS_PER_PAGE) {
            currentItemCount += groupedItems.value[date].length;
            startIndex = i + 1;
        } else {
            break;
        }
    }
    
    // Add dates and items until we reach the items per page limit
    for (let i = startIndex; i < allDates.length; i++) {
        const date = allDates[i];
        const dateItems = groupedItems.value[date];
        
        if (itemCount >= ITEMS_PER_PAGE) break;
        
        if (itemCount + dateItems.length <= ITEMS_PER_PAGE) {
            result[date] = dateItems;
            itemCount += dateItems.length;
        } else {
            // Only take the needed items from this date group
            const remainingSlots = ITEMS_PER_PAGE - itemCount;
            result[date] = dateItems.slice(0, remainingSlots);
            itemCount += remainingSlots;
        }
    }
    
    return result;
});

const totalPages = computed(() => {
    if (!list.value?.items) return 0;
    return Math.ceil(list.value.items.length / ITEMS_PER_PAGE);
});

const formatDateHeader = (dateStr) => {
    const date = parseISO(dateStr);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (format(date, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
        return 'Today';
    } else if (format(date, 'yyyy-MM-dd') === format(yesterday, 'yyyy-MM-dd')) {
        return 'Yesterday';
    }
    return format(date, 'MMMM d, yyyy');
};

const fetchList = async () => {
    error.value = null;
    isRefreshingContents.value = true;
    try {
        log.debug('Fetching inbox for user');
        const listData = await inboxService.get();
        list.value = {
            ...listData,
            items: Array.isArray(listData.items) ? listData.items : [],
            author: listData.owner || {},
            topics: Array.isArray(listData.topics) ? listData.topics : [],
            breadcrumb: Array.isArray(listData.breadcrumb) ? listData.breadcrumb : [],
        };
        log.debug('list value obtained:', list.value);
    } catch (err) {
        log.error('Error fetching list:', err);
        if (err.response) {
            switch (err.response.status) {
                case 401:
                    error.value = "You are not authorized to view this list. Please log in or check your permissions.";
                    break;
                case 404:
                    error.value = "The requested list could not be found. It may have been deleted or you may have entered an incorrect URL.";
                    break;
                default:
                    error.value = "An error occurred while fetching the list. Please try again later.";
            }
        } else if (err.request) {
            error.value = "Unable to reach the server. Please check your internet connection and try again.";
        } else {
            error.value = "An unexpected error occurred. Please try again later.";
        }
    } finally {
        isRefreshingContents.value = false;
    }
};

onMounted(async () => {
    log.trace("on Mounted starts");
    try {
        await fetchList();
    } finally {
        isLoading.value = false;
    }
});

onUpdated(() => {
    feather.replace();
});

const openDeleteConfirmation = (item) => {
    log.trace("Opening delete confirm modal: ", item);
    itemToDelete.value = item;
    showConfirmModal.value = true;
    openMenuItemKey.value = null; // Close the menu when opening the confirm modal
};

const cancelDelete = () => {
    showConfirmModal.value = false;
    itemToDelete.value = null;
};

const confirmDelete = async () => {
    if (itemToDelete.value) {
        isDeletingItem.value = true;
        try {
            await deleteItem(itemToDelete.value);
            showConfirmModal.value = false;
            itemToDelete.value = null;
        } finally {
            isDeletingItem.value = false;
        }
    }
};

const deleteItem = async (item) => {
    try {
        log.debug('Deleting item:', item);
        if (!item.itemkey) {
            throw new Error('Item key is missing');
        }

        await inboxService.erase(item.itemkey);
        log.debug('Item deleted successfully');

        await refreshListContents();

        // Optionally, show a success message
        log.debug('Item deleted successfully');
    } catch (error) {
        log.error('Error deleting item:', error);
        alert('Failed to delete item. Please try again.');
    }
};

const refreshListContents = async () => {
    isRefreshingContents.value = true;
    try {
        log.debug('Refreshing inbox contents');
        const listData = await inboxService.get();
        // Only update the items array
        list.value.imageUrl = listData.imageUrl;
        list.value.items = Array.isArray(listData.items) ? listData.items : [];
    } catch (err) {
        log.error('Error refreshing list contents:', err);
        // Optionally, show an error message to the user
    } finally {
        isRefreshingContents.value = false;
    }
};

</script>

<style scoped>
.refresh-icon {
    /* Your existing button styles */
    transition: transform 0.3s ease;
}

.refresh-icon.is-refreshing {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.list-view {
    max-width: 800px;
    margin: 0 auto;
}

.header-buttons {
    top: 10px;
    left: 10px;
    display: flex;
    gap: 10px;
}

.button.is-light {
    background-color: #f5f5f5;
    color: #363636;
}

.button.is-success {
    background-color: #48c774;
    color: #fff;
}

.header-listmode-button {
    position: absolute;
    top: 10px;
}

.header-edit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.title-container .title {
    line-height: 1.5;
    margin: 0;
    padding: 4px;
}

.title-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.refresh-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.refresh-button:hover {
    background-color: #f5f5f5;
}

.breadcrumb {
    padding: 0.5rem 0;
    margin-bottom: 0;
    font-size: 0.9rem;
    margin-bottom: 0 !important;
    align-content: center;
    vertical-align: middle;
}

.breadcrumb ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    vertical-align: middle;
}

.breadcrumb a {
    padding: 2px;
    color: grey;
}

.breadcrumb span {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

.breadcrumb li {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    color: #999;
    align-items: center;
    vertical-align: middle;
}

.breadcrumb li .icon {
    margin-right: 2px !important;
    margin-left: 2px !important;
}

.breadcrumb li.is-active {
    color: #000;
}

.list-contents-container {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
}

.options-menu {
    display: flex;
    align-items: top;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 10px;
}

.options-button {
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-info {
    margin-bottom: 20px;
}

.info-container {
    display: flex;
    justify-content: space-between;
}

.meta-info,
.stats-info {
    display: block;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #666;
}

.meta-info div {
    display: flex;
    margin: 10px 10px;
}

.meta-info div {
    display: flex;
}

.meta-info .icon {
    margin-right: 10px;
}

.description {
    margin: 0.5em;
}

.topics {
    margin: 0.5em;
}

.tag {
    margin-right: 5px;
    margin-bottom: 5px;
}

.author-info {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.author-info a {
    text-decoration: none;
    color: inherit;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 100%;
}

.author-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.author-name {
    align-items: center;
    font-weight: bold;
    font-size: 1.1em;
}

.certified-icon {
    margin-left: 5px;
    color: #3273dc;
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
}

.author-stats {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
}

.author-stats div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: small;
}

.author-stats i {
    margin-right: 5px;
    width: 1em;
    height: 1em;
}

.list-items-total {
    text-align: center;
    padding: 0.2em;
    color: grey;
    font-size: 0.8em;
}

.list-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: fit-content;
}

.button {
    margin-left: 10px;
}

.error-message,
.loading {
    text-align: center;
    margin-top: 50px;
}

.section-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 10px;
    border: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    border-radius: 8px;
    font-size: smaller;
}

.section-menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
}

.section-menu-item .icon {
    margin-right: 6px;
}

.progress {
    max-width: 300px;
    margin: 20px auto;
}

.certified-icon {
    margin-left: 5px;
    color: #3273dc;
    /* You can change this to match your color scheme */
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
}

.button i {
    margin-right: 5px;
}

.loading-contents {
    text-align: center;
    padding: 20px;
}

.loading-contents .progress {
    max-width: 300px;
    margin: 10px auto;
}

.error-message {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    color: #856404;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
}

.error-message .icon-text {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.error-message .icon-text .icon {
    color: #856404;
    margin: auto;
}

.error-message .button .icon {
    margin-right: 0.5rem;
}

.empty-list-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    margin: 20px 0;
}

.empty-list-message .message {
    margin-bottom: 20px;
    background-color: white;
}

.empty-list-message h3 {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
}

.empty-list-message p {
    margin-bottom: 20px;
}

.empty-list-message .actions {
    display: flex;
    gap: 10px;
}

.empty-list-message .button {
    display: flex;
    align-items: center;
    gap: 5px;
}

.list-items {
    display: flex;
    flex-direction: column;
}

.list-item-count {
    margin: 0.5em;
}

.list-items.single-line>*:not(:last-child),
.list-items.multi-line>*:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
    flex-direction: column;
}

.list-items.tile {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: row;
}

.list-actions {
    display: flex;
    justify-content: flex-end;
}

.list-actions span {
    margin-left: 4px;
}

.loader-container {
    display: flex;
    /* Changed from block to flex */
    flex-direction: column;
    /* Stack items vertically */
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 300px;
    padding: 1em;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3273dc;
    animation: spin 1s linear infinite;
    margin: auto 0;
}

.loader-label {
    margin: 4em;
    color: steelblue;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.date-group-header {
    padding: 12px 16px;
    background-color: #f5f5f5;
    font-weight: 600;
    color: #363636;
    border-bottom: 1px solid #e0e0e0;
    font-size: 0.9rem;
}

.date-group-items {
    display: flex;
    flex-direction: column;
}

.date-group-items:not(:last-child) {
    margin-bottom: 1rem;
}

/* Update existing list-items styles */
.list-items.single-line .date-group-items > *:not(:last-child),
.list-items.multi-line .date-group-items > *:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
}

.list-items.tile .date-group-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: row;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-top: 1px solid #e0e0e0;
}

.pagination-info {
    color: #666;
}
</style>
