import axios from 'axios';
import log from 'loglevel';

export default class BaseApiService {
  constructor() {
    const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8001/api';
    
    this.api = axios.create({
      baseURL,
      withCredentials: true
    });

    this.api.interceptors.request.use(
      (config) => {
        log.debug(`${config.method.toUpperCase()} ${config.url}`);
        return config;
      },
      (error) => {
        log.error('Request error:', error);
        return Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        log.error('Response error:', error);
        return Promise.reject(error);
      }
    );
  }
}