<template>
    <div class="modal" :class="{ 'is-active': isActive }">
        <div class="modal-background" @click="cancel"></div>
        <div class="modal-content">
            <div class="box">
                <h2 class="title is-4">Confirm Deletion</h2>
                <p>Are you sure you want to delete this item?</p>
                <p><strong>This action is final and cannot be undone. The item will be permanently removed from the
                        list.</strong></p>
                <div class="buttons mt-4">
                    <button class="button is-danger" @click="confirm"
                        :class="{ 'is-loading': isDeleting }">Delete</button>
                    <button class="button" @click="cancel" :disabled="isDeleting">Cancel</button>
                </div>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="cancel" :disabled="isDeleting"></button>
    </div>
</template>

<script setup>

defineProps({
    isActive: {
        type: Boolean,
        required: true
    },
    isDeleting: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['confirm', 'cancel']);

const confirm = () => emit('confirm');
const cancel = () => emit('cancel');
</script>

<style scoped>
.box {
    background-color: white;
}
</style>