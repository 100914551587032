<template>
    <div v-if="!authStore.isAuthenticated" class="powered-by-curalist">
        <div class="label">Powered by</div>
        <div><img class="logo" src="/img/curalist-name.svg" alt="Curalist logo"></div>
        <nav class="nav-menu">
            <ul>
                <li><a href="/signup">Sign up</a></li>
                <li><a href="/login">Login</a></li>
                <li><a href="/docs/learn">Learn more</a></li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();
</script>

<style scoped>
.powered-by-curalist {
    text-align: center;
    margin-top: 1em;
}

.powered-by-curalist .label {
    font-size: 0.6em;
    font-weight: 100;
}

.powered-by-curalist .logo {
    text-align: center;
    height: 1.2em;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.nav-menu li {
  margin: 0;
  padding: 0 10px;
  position: relative;
}

.nav-menu li:not(:last-child)::after {
  content: '|';
  position: absolute;
  right: 0;
}

.nav-menu a {
  text-decoration: none;
  color: #666;
}

.nav-menu a:hover {
  color: #333;
}

</style>