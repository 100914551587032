<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background" @click="emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Wish to report this content?</p>
        <button class="delete" aria-label="close" @click="emit('close')"></button>
      </header>

      <section class="modal-card-body">
        <div v-if="isSubmitted" class="submission-success">
          <span class="icon is-large has-text-success">
            <i class="fas fa-check-circle fa-2x"></i>
          </span>
          <h3 class="title is-4">Your report has been submitted</h3>
          <p class="message">
            Thank you for helping keep our community safe. Our moderation team will review your report as soon as
            possible. At Curalist, we are committed to creating a safe and inclusive platform that fosters positive
            interactions among our members.
          </p>
          <p class="message">
            Please note that while we investigate all reports, we will only contact you if additional information is
            needed. We do not provide updates on the outcome of our investigations, but rest assured that appropriate
            action will be taken if necessary.
          </p>
        </div>
        <form v-else @submit.prevent="submitReport">
          <template v-if="!isAuthenticated">
            <div class="field">
              <label class="label">Your Name</label>
              <div class="control">
                <input class="input" type="text" v-model="reportData.reporterName" placeholder="Enter your name"
                  required />
              </div>
            </div>

            <div class="field">
              <div class="label-wrapper">
                <label class="label">Your Email</label>
                <span v-if="reportData.reporterEmail.trim() && !isValidEmail(reportData.reporterEmail)"
                  class="validation-message">
                  <span class="icon is-small">
                    <i class="fas fa-exclamation-circle"></i>
                  </span>
                  <span>Not a valid email</span>
                </span>
                <span v-else-if="reportData.reporterEmail.trim() && isValidEmail(reportData.reporterEmail)"
                  class="validation-message is-success">
                  <span class="icon is-small">
                    <i class="fas fa-check"></i>
                  </span>
                </span>
              </div>
              <div class="control">
                <input class="input" type="email" v-model="reportData.reporterEmail" placeholder="Enter your email"
                  required />
              </div>
              <p class="help">We may need to contact you about this report</p>
            </div>

            <div class="divider">Report Details</div>
          </template>

          <div class="field">
            <label class="label">Reason for Report</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="reportData.reason" required>
                  <option value="">Select a reason</option>
                  <option value="inappropriate">Inappropriate Content</option>
                  <option value="spam">Spam</option>
                  <option value="copyright">Copyright Violation</option>
                  <option value="misinformation">Misinformation</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Explain your concerns</label>
            <div class="control">
              <textarea class="textarea" v-model="reportData.details"
                placeholder="You must provide some text to explain why you are reporting this content."
                required></textarea>
            </div>
          </div>

          <div v-if="error" class="notification is-danger">
            {{ error }}
          </div>
        </form>
      </section>

      <footer class="modal-card-foot">
        <div class="footer-content">
          <div v-if="isSubmitting" class="loading-container">
            <span class="loader"></span>
            <span class="loading-text">Submitting report...</span>
          </div>
          <template v-else-if="isSubmitted">
            <button class="button is-primary" @click="emit('close')">Close this window</button>
          </template>
          <template v-else>
            <button class="button" @click="emit('close')">Cancel</button>
            <button class="button is-primary" @click="submitReport" :disabled="!isValid">
              Submit Report
            </button>
          </template>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import log from 'loglevel';
import { useAuthStore } from '@/stores/auth';
import { reportContentService } from '@/services/api/ReportContentService';

const props = defineProps({
  isActive: Boolean,
  item: {
    type: Object,
    required: true
  }
});

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);

const emit = defineEmits(['close', 'submit']);

const isSubmitting = ref(false);
const error = ref('');
const isSubmitted = ref(false);

const reportData = ref({
  reason: '',
  details: '',
  reporterName: '',
  reporterEmail: ''
});

const isValid = computed(() => {
  const hasRequiredFields = reportData.value.reason &&
    reportData.value.details.length >= 10;

  if (!isAuthenticated.value) {
    return hasRequiredFields &&
      reportData.value.reporterName.length >= 2 &&
      isValidEmail(reportData.value.reporterEmail);
  }

  return hasRequiredFields;
});

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return emailRegex.test(email) && email.length >= 5;
};

const submitReport = async () => {
  if (!isValid.value) return;

  try {
    isSubmitting.value = true;
    error.value = '';

    // Prepare report data
    const report = {
      reportType: 'contentIssue',
      reason: reportData.value.reason,
      details: reportData.value.details,
      content: {
        type: props.item.itemType,
        properties: {
          userkey: props.item.userkey,
          listkey: props.item.listkey,
          linkkey: props.item.itemkey
        }
      }
    };

    // Add reporter info if user is not authenticated
    if (!isAuthenticated.value) {
      report.author = {
        name: reportData.value.reporterName,
        email: reportData.value.reporterEmail
      }
    }

    // Submit the report using the API
    await reportContentService.submit(report);

    // Instead of closing, show success state
    isSubmitted.value = true;

  } catch (err) {
    log.error('Error submitting report:', err);
    error.value = 'Failed to submit report. Please try again.';
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<style scoped>
.loading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-card {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.divider {
  margin: 2rem 0 1rem;
  font-weight: bold;
  color: #666;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 0.5rem;
}

.help {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  color: #666;
}

.validation-message {
  color: #f14668;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.icon {
  display: inline-flex;
  align-items: center;
}

.label-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5em;
}

.validation-message {
  color: #f14668;
  font-size: 0.875rem;
  gap: 0.25rem;
  margin-bottom: 0.5em;
  margin-top: 0;
}

.validation-message.is-success {
  color: #48c774;
  /* Bulma's success color */
}

.submission-success {
  text-align: center;
}

.submission-success .icon {
  margin-bottom: 1rem;
}

.submission-success .message {
  color: #666;
  background-color: #fff;
  line-height: 1.5;
}

.modal-card-foot {
  justify-content: flex-end;
}

.footer-content {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  width: 100%;
}
</style>