import BaseApiService from './BaseApiService';
import log from 'loglevel';

class ReportContentService extends BaseApiService {

  async submit(data) {
    try {
      log.trace('Submitting report to /feedback/report', data);
      const response = await this.api.post('/feedback/report', data);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error submitting report:', error);
      throw error;
    }
  }

}

export const reportContentService = new ReportContentService();
export const { submit } = reportContentService;