<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <div class="box image-upload-box">
        <h2 class="title is-4">{{ modalTitle }}</h2>
        
        <div v-if="!isUploading && !uploadStatus">
          <div class="content">
            <p><strong>Instructions:</strong></p>
            <ul>
              <li>Allowed image types: JPG, PNG, GIF</li>
              <li>Maximum file size: 5 MB</li>
              <li>Recommended dimensions: 1200x300 pixels</li>
              <li>The image will be used as the header background for your list</li>
            </ul>
          </div>

          <div class="file has-name is-fullwidth">
            <label class="file-label">
              <input class="file-input" type="file" @change="handleFileChange" accept="image/jpeg,image/png,image/gif">
              <span class="file-cta">
                <span class="file-icon">
                  <FeatherIcon icon="upload" />
                </span>
                <span class="file-label">
                  Choose a file…
                </span>
              </span>
              <span class="file-name">
                {{ fileName || "No file selected" }}
              </span>
            </label>
          </div>
          
          <p v-if="fileError" class="has-text-danger mt-2">{{ fileError }}</p>

          <button class="button is-primary mt-4" @click="uploadImage" :disabled="!isFileValid">
            Upload
          </button>
        </div>

        <div v-else-if="isUploading" class="uploading-content">
          <div class="loader"></div>
          <p>Uploading image...</p>
        </div>

        <div v-else-if="uploadStatus === 'success'" class="success-content">
          <FeatherIcon icon="check-circle" size="48" class="has-text-success" />
          <p>Image uploaded successfully!</p>
        </div>

        <div v-else-if="uploadStatus === 'error'" class="error-content">
          <FeatherIcon icon="alert-circle" size="48" class="has-text-danger" />
          <p>Failed to upload image. Please try again.</p>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="close"></button>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import FeatherIcon from './FeatherIcon.vue';
import { uploadListImage } from '@/services/api'; // Import the API function
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const props = defineProps({
  isActive: Boolean,
  userkey: {
    type: String,
    required: true
  },
  listkey: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['close', 'uploaded']);

const selectedFile = ref(null);
const fileName = ref('');
const fileError = ref('');
const isUploading = ref(false);
const uploadStatus = ref(null);

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const isFileValid = computed(() => {
  return selectedFile.value && !fileError.value;
});

const modalTitle = computed(() => {
  if (isUploading.value) return 'Uploading Image';
  if (uploadStatus.value === 'success') return 'Upload Successful';
  if (uploadStatus.value === 'error') return 'Upload Failed';
  return 'Upload Header Image';
});

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > MAX_FILE_SIZE) {
      fileError.value = 'File size exceeds 5 MB limit.';
      selectedFile.value = null;
      fileName.value = '';
    } else {
      fileError.value = '';
      selectedFile.value = file;
      fileName.value = file.name;
    }
  } else {
    fileError.value = '';
    selectedFile.value = null;
    fileName.value = '';
  }
};

const uploadImage = async () => {
  if (selectedFile.value) {
    isUploading.value = true;
    uploadStatus.value = null;
    try {
      const formData = new FormData();
      formData.append('image', selectedFile.value);
      
      const response = await uploadListImage(props.userkey, props.listkey, formData);
      
      uploadStatus.value = 'success';
      log.debug("Upload image response:", response);
      log.debug("New image Url:", response.data.values.imageUrl);
      emit('uploaded', response.data.values.imageUrl);
      setTimeout(() => close(), 2000); // Close modal after 2 seconds
    } catch (error) {
      console.error('Error uploading image:', error);
      uploadStatus.value = 'error';
    } finally {
      isUploading.value = false;
    }
  }
};

const close = () => {
  emit('close');
  selectedFile.value = null;
  fileName.value = '';
  fileError.value = '';
  isUploading.value = false;
  uploadStatus.value = null;
};
</script>

<style scoped>
.content ul {
  margin-left: 1em;
}

.modal-content {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.image-upload-box {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  padding: 1.25rem;
}

.file-label {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #4a4a4a;
}

.file-cta {
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.file-name {
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  max-width: none;
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.file-icon {
  height: 2.5em;
  width: 2.5em;
}

.file-label:hover .file-cta {
  background-color: #eee;
  color: #363636;
}

.button.is-primary {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
}

.button.is-primary:hover {
  background-color: #2366d1;
}

.button.is-primary:disabled {
  background-color: #3273dc;
  opacity: 0.5;
  cursor: not-allowed;
}

.uploading-content,
.success-content,
.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-content,
.error-content {
  text-align: center;
}

.success-content .feather,
.error-content .feather {
  margin-bottom: 16px;
}
</style>