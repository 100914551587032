<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <div class="card">
        <div class="card-header">
          <h3 class="card-header-title">{{ isEditing ? 'Edit Sub-List' : 'Add Sub-List' }}</h3>
          <button class="delete" aria-label="close" @click="close"></button>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label" for="title">Title</label>
            <div class="control">
              <input class="input" type="text" id="title" v-model="formData.title" required />
            </div>
          </div>
          <div class="field">
            <label class="label" for="description">Description</label>
            <div class="control">
              <textarea class="textarea" id="description" v-model="formData.description"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label" for="topics">Topics (comma-separated list)</label>
            <div class="control">
              <input class="input" type="text" id="topics" v-model="formData.topics" />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="button" @click="close">Cancel</button>
          <button class="button is-primary" @click="handleSubmit">
            {{ isEditing ? 'Update' : 'Add' }} Sub-List
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { addSublist, updateList } from '@/services/api';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const props = defineProps({
  isActive: {
    type: Boolean,
    required: true,
  },
  userKey: {
    type: String,
    required: true,
  },
  parentListKey: {
    type: String,
    required: true,
  },
  editingList: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close', 'list-added', 'list-updated']);

const formData = ref({
  title: '',
  description: '',
  topics: '',
});

const isEditing = ref(false);

const resetForm = () => {
  formData.value = {
    title: '',
    description: '',
    topics: '',
  };
};

// Watch for changes in the editingList prop
watch(() => props.editingList, (newVal) => {
  log.debug('editingList changed:', newVal);
  if (newVal) {
    formData.value = { 
      title: newVal.title || '',
      description: newVal.description || '',
      topics: Array.isArray(newVal.topics) ? newVal.topics.join(', ') : '',
    };
    isEditing.value = true;
    log.info('Form populated for editing:', formData.value);
  } else {
    resetForm();
    isEditing.value = false;
    log.info('Form reset for new list');
  }
}, { immediate: true });

watch(() => props.isActive, (newVal) => {
  log.trace("Executing watch in AddListModal (isActive)")
  if (newVal) {
    // Modal is being opened
    log.debug('Modal opened. editingList:', props.editingList);
    if (props.editingList) {
      log.info('Editing existing list:', props.editingList.title);
    } else {
      log.info('Adding new list');
    }
    addKeyListener();
    if (!props.editingList) {
      resetForm();
    }
  } else {
    // Modal is being closed
    log.debug('Modal closed');
    removeKeyListener();
  }
});

const addKeyListener = () => {
  document.addEventListener('keydown', handleKeyDown);
};

const removeKeyListener = () => {
  document.removeEventListener('keydown', handleKeyDown);
};

const handleKeyDown = (event) => {
  if (event.key === 'Escape') {
    close();
  }
};

const handleSubmit = async () => {
  try {
    const data = {
      ...formData.value,
      topics: formData.value.topics.split(',').map(topic => topic.trim())
    };

    if (isEditing.value) {
      log.debug("Updating list", {
        userkey: props.userKey,
        listkey: props.editingList.listkey,
        data: data
      });
      await updateList(props.userKey, props.editingList.listkey, data);
      emit('list-updated', data);
    } else {
      log.debug("Adding list", {
        userkey: props.userKey,
        listkey: props.parentListKey,
        data: data
      });
      const newList = await addSublist(props.userKey, props.parentListKey, data);
      emit('list-added', newList);
    }

    close();
  } catch (error) {
    console.error('Error submitting list:', error);
    // Handle error (e.g., show error message to user)
  }
};

const close = () => {
  resetForm();
  emit('close');  // Use emit instead of this.$emit
  removeKeyListener();
};
</script>

<style scoped>
.card-header {
  align-items: center;
  padding-right: 20px;
}

.card-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.card-footer .button {
  margin-left: 0.5rem;
}

.control.has-icons-right {
  display: flex;
  align-items: center;
}

.control.has-icons-right .button {
  margin-left: 0.5rem;
}
</style>