<template>
    <h1>Users</h1>

    <BreadcrumbNavbar :items="breadcrumbItems" />

    <div class="list-grid-view">
        <div class="list-controls">
            <div class="control">
                <input class="input" type="text" v-model="filter" placeholder="Search users...">
            </div>
            <div class="control">
                <div class="select">
                    <select v-model="pageSize">
                        <option :value="10">10 per page</option>
                        <option :value="20">20 per page</option>
                        <option :value="50">50 per page</option>
                    </select>
                </div>
            </div>
            <div class="control">
                <button @click="toggleColumnSelector" class="button is-primary">
                    <FeatherIcon icon="columns" size="16" />
                    <span>Toggle Columns</span>
                </button>
                <button @click="refreshLists" class="button is-primary rounded"><FeatherIcon icon="refresh-ccw" size="16" /></button>
            </div>
        </div>

        <div class="table-container">
            <table class="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th v-for="column in visibleColumns" :key="column.key" @click="sort(column.key)">
                            <span>{{ column.label }}</span>
                            <FeatherIcon :icon="getSortIcon(column.key)" size="14" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in paginatedAndFilteredLists" :key="list.id" @click="navigateToList(list)"
                        class="clickable-row">
                        <td v-for="column in visibleColumns" :key="column.key">
                            <template v-if="column.key === 'isPublic'">
                                <div style="display: flex;">
                                    <FeatherIcon v-if="list.isPublic" icon="eye" size="18" style="margin: 0 auto" />
                                    <FeatherIcon v-else icon="lock" size="18" style="margin: 0 auto" />
                                </div>
                            </template>
                            <template v-else-if="column.key === 'rating'">
                                <div class="rating">
                                    <FeatherIcon v-for="star in 5" :key="star" icon="star" size="14"
                                        :class="{ 'is-filled': list.rating >= star }" />
                                </div>
                            </template>
                            <template v-else-if="['createdDate', 'lastUpdated'].includes(column.key)">
                                {{ formatDate(list[column.key]) }}
                            </template>
                            <template v-else>
                                {{ list[column.key] }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <nav class="pagination is-centered" role="navigation" aria-label="pagination">
            <a class="pagination-previous" @click="previousPage" :disabled="currentPage === 1">Previous</a>
            <a class="pagination-next" @click="nextPage" :disabled="currentPage === totalPages">Next page</a>
            <ul class="pagination-list">
                <li v-for="page in totalPages" :key="page">
                    <a class="pagination-link" :class="{ 'is-current': currentPage === page }"
                        @click="goToPage(page)">{{ page
                        }}</a>
                </li>
            </ul>
        </nav>

        <!-- Friendkit Modal for Column Selection -->
        <div class="modal" :class="{ 'is-active': isColumnSelectorVisible }">
            <div class="modal-background" @click="toggleColumnSelector"></div>
            <div class="modal-content">
                <div class="card">
                    <div class="card-head">
                        <p class="card-head-title">Select Columns</p>
                        <button class="card-head-icon" @click="toggleColumnSelector">
                            <FeatherIcon icon="x" size="24" />
                        </button>
                    </div>
                    <div class="card-content">
                        <div class="content">
                            <div v-for="column in columns" :key="column.key" class="field">
                                <div class="control">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="column.visible">
                                        {{ column.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer class="card-footer">
                        <a class="card-footer-item" @click="toggleColumnSelector">Close</a>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import BreadcrumbNavbar from '@/components/BreadcrumbNavbar.vue';
import FeatherIcon from '@/components/FeatherIcon.vue';
import { fetchUsers } from '@/services/api';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const router = useRouter();

const breadcrumbItems = ref([
  { text: 'Administration', href: '/admin', icon: 'sliders', active: false },
  { text: 'Users', icon: 'users', active: true }
]);

const lists = ref([]);
const filter = ref('');
const sortBy = ref('title');
const sortDesc = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const isColumnSelectorVisible = ref(false);

const columns = ref([
    { key: 'username', label: 'Username', visible: true },
    { key: 'totalLists', label: 'Lists', visible: true },
    { key: 'createdDate', label: 'Created', visible: false },
    { key: 'lastUpdated', label: 'Updated', visible: true },
    { key: 'totalFollowers', label: 'Followers', visible: false },
    { key: 'rating', label: 'Rating', visible: false },
]);

const visibleColumns = computed(() => columns.value.filter(column => column.visible));

onMounted(async () => {
    try {
        lists.value = await fetchUsers();
    } catch (error) {
        log.error('Failed to fetch lists:', error);
    }
});

const toggleColumnSelector = () => {
    isColumnSelectorVisible.value = !isColumnSelectorVisible.value;
};

const filteredLists = computed(() => {
    return lists.value.filter(list =>
        list.username.toLowerCase().includes(filter.value.toLowerCase())
    );
});

const sortedLists = computed(() => {
    return [...filteredLists.value].sort((a, b) => {
        let modifier = sortDesc.value ? -1 : 1;
        if (a[sortBy.value] < b[sortBy.value]) return -1 * modifier;
        if (a[sortBy.value] > b[sortBy.value]) return 1 * modifier;
        return 0;
    });
});

const paginatedAndFilteredLists = computed(() => {
    const startIndex = (currentPage.value - 1) * pageSize.value;
    const endIndex = startIndex + pageSize.value;
    return sortedLists.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
    return Math.ceil(sortedLists.value.length / pageSize.value);
});

const sort = (column) => {
    if (sortBy.value === column) {
        sortDesc.value = !sortDesc.value;
    } else {
        sortBy.value = column;
        sortDesc.value = false;
    }
};

const getSortIcon = (column) => {
    if (sortBy.value !== column) return 'chevron-up';
    return sortDesc.value ? 'chevron-down' : 'chevron-up';
};

const previousPage = () => {
    if (currentPage.value > 1) currentPage.value--;
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) currentPage.value++;
};

const goToPage = (page) => {
    currentPage.value = page;
};

const navigateToList = (list) => {
    router.push(`/users/${list.userkey}/lists/${list.listkey}`);
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
};
</script>

<style scoped>
.list-grid-view {
    padding: 20px;
}

.list-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.table-container {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.table th {
    cursor: pointer;
}

.table th span {
    margin-right: 5px;
}

.list-icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 4px;
}

.rating {
    display: flex;
}

.rating .feather {
    color: #d4d4d4;
    margin-right: 2px;
}

.rating .is-filled {
    color: #ffd700;
}

.pagination-link.is-current {
    background-color: #7F00FF;
    border-color: #7F00FF;
}

/* Modal styles */
.modal-content {
    width: 80%;
    max-width: 500px;
}

.card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-head-title {
    font-weight: bold;
    margin: 0;
}

.card-head-icon {
    background: none;
    border: none;
    cursor: pointer;
}

.card-content {
    padding: 1rem;
}

.field {
    margin-bottom: 0.5rem;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input[type="checkbox"] {
    margin-right: 0.5rem;
}

.clickable-row {
    cursor: pointer;
}

.clickable-row:hover {
    background-color: #f5f5f5;
}
</style>