import { defineStore } from 'pinia'
import { fetchAuthStatus, logout } from '@/services/auth'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    user: null,
    userkey: null,
    roles: [],
    claims: []
  }),
  getters: {
    getUserkey: (state) => state.userkey
  },
  actions: {
    async checkAuth() {
      try {
        const status = await fetchAuthStatus()
        this.isAuthenticated = status.isAuthenticated
        this.user = status.name
        this.roles = status.roles || []
        this.claims = status.claims || []
        
        // Find and set the userkey from claims
        const userkeyClaim = this.claims.find(claim => claim.type === "http://schemas.curalist/identity/claims/userkey")
        this.userkey = userkeyClaim ? userkeyClaim.value : null

      } catch (error) {
        console.error('Failed to check auth status:', error)
        this.resetAuthState()
      }
    },
    async logoutUser() {
      try {
        await logout()
        this.resetAuthState()
        this.removeCookies()
      } catch (error) {
        console.error('Logout failed:', error)
        throw error
      }
    },
    resetAuthState() {
      this.isAuthenticated = false
      this.user = null
      this.userkey = null
      this.roles = []
      this.claims = []
    },
    removeCookies() {
      // Remove .AspNetCore.Cookies
      document.cookie = '.AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      
      // Remove oauth_state
      document.cookie = 'oauth_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      
      // If your cookies are set with specific domain or secure flags, include those:
      // document.cookie = '.AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourdomainhere.com; secure;'
      // document.cookie = 'oauth_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourdomainhere.com; secure;'
    }
  }
})