import BaseApiService from './BaseApiService';
import log from 'loglevel';

class FeedService extends BaseApiService {
  constructor() {
    super();
  }

  async fetch(page = 1) {
    try {
      log.debug(`Fetching feed page ${page}`);
      const response = await this.api.get('/feed', {
        params: { page }
      });
      return response.data;
    } catch (error) {
      log.error('Error fetching feed items:', error);
      throw error;
    }
  }
}

export const feedService = new FeedService();
export const { fetch } = feedService;