<template>
  <div class="app-container" :style="rootStyles">
    <LeftMenu :class="{ 'hidden': !showLeftMenu }" />
    <TopMenu v-if="showTopMenu" />
    <main class="content-area">
      <router-view></router-view>
      <BottomNavigation />
    </main>
    <BottomMenu :class="{ 'hidden': showLeftMenu }" />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import LeftMenu from './components/LeftMenu.vue';
import TopMenu from './components/TopMenu.vue';
import BottomNavigation from './components/BottomNavigation.vue';
import BottomMenu from './components/BottomMenu.vue';
import { useAuthStore } from '@/stores/auth';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const authStore = useAuthStore();
const rootStyles = computed(() => ({
  '--is-authenticated': authStore.isAuthenticated ? '1' : '0'
}));

const showLeftMenu = ref(window.innerWidth >= 768);

const handleResize = () => {
  showLeftMenu.value = authStore.isAuthenticated && window.innerWidth >= 768;
  log.debug('handleResize started to ', showLeftMenu.value);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const showTopMenu = computed(() => {
  if (!authStore.isAuthenticated)
    return true;
  if (authStore.isAuthenticated && window.innerWidth < 768)
    return true;
  return false;
});
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-area {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.5rem;
}

.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .content-area {
    margin-bottom: calc(60px * var(--is-authenticated));
  }
}

@media screen and (min-width: 769px) {
  .content-area {
    padding: 0px 12px !important;
    margin-left: 60px;
  }
}
</style>