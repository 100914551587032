<template>
  <div v-if="user" class="user-card-link">
    <router-link 
      :to="{ name: 'UserProfile', params: { userkey: user.userkey } }" 
    >
      <div class="user-card">
        <div class="user-header">
          <img :src="user.profilePhoto || defaultProfilePhoto" :alt="user.username" class="profile-photo">
          <div class="user-info">
            <h3 class="username">
              {{ user.username || 'Unknown User' }}
              <span v-if="user.isCertified" class="icon has-text-info" title="Certified User">
                <i class="fas fa-check-circle"></i>
              </span>
            </h3>
            <p class="bio">{{ user.bio || '' }}</p>
          </div>
        </div>
        <div class="user-stats">
          <div v-if="user.totalLists !== undefined" class="stat">
            <span class="icon"><i class="fas fa-list"></i></span>
            <span>{{ user.totalLists }} Lists</span>
          </div>
          <div v-if="user.rating !== undefined" class="stat">
            <span class="icon"><i class="fas fa-star"></i></span>
            <span>{{ user.rating.toFixed(1) }} Rating</span>
          </div>
          <div v-if="user.badges && user.badges.length" class="stat">
            <span class="icon"><i class="fas fa-award"></i></span>
            <span>{{ user.badges.length }} Badges</span>
          </div>
          <div v-if="user.followers !== undefined" class="stat">
            <span class="icon"><i class="fas fa-users"></i></span>
            <span>{{ user.followers }} Followers</span>
          </div>
          <div v-if="user.following !== undefined" class="stat">
            <span class="icon"><i class="fas fa-user-friends"></i></span>
            <span>{{ user.following }} Following</span>
          </div>
        </div>
        <div v-if="user.favoriteTopics && user.favoriteTopics.length" class="favorite-topics">
          <h4>Favorite Topics</h4>
          <div class="tags">
            <span v-for="topic in user.favoriteTopics" :key="topic" class="tag is-info is-light">
              {{ topic }}
            </span>
          </div>
        </div>
        <div v-if="user.badges && user.badges.length" class="badges">
          <h4>Badges</h4>
          <div class="badge-list">
            <span v-for="badge in user.badges" :key="badge" class="icon" :title="badge">
              <i class="fas fa-medal"></i>
            </span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const defaultProfilePhoto = ref('/img/default-profile.png');

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  console.log('User object:', props.user);
  console.table(props.user); // This will display the object properties in a table format
  
  // If you want to use the configured log level
  log.debug('User object:', props.user);
});
</script>

<style scoped>
.user-card-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.user-card-link:hover .user-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.user-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.user-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.username {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
}

.bio {
  color: #666;
  font-size: 0.9em;
}

.user-stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.stat {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.stat .icon {
  margin-right: 5px;
  color: #3273dc;
}

.favorite-topics, .badges {
  margin-top: 20px;
}

.favorite-topics h4, .badges h4 {
  margin-bottom: 10px;
  font-weight: bold;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.badge-list {
  display: flex;
  flex-wrap: wrap;
}

.badge-list .icon {
  font-size: 1.5em;
  color: #ffd700;
  margin-right: 10px;
}
</style>
