<template>
    <nav v-if="!isAuthenticated || (isAuthenticated && isHomeRoute)" class="horizontal-nav">
        <ul>
            <li><a href="/docs/about">About</a></li>
            <li><a href="/docs/terms">Terms</a></li>
            <li><a href="/docs/privacy">Privacy</a></li>
            <li><a href="/docs/faq">FAQ</a></li>
            <li><a href="/docs/contact">Contact</a></li>
        </ul>
    </nav>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const route = useRoute();

// Use the auth store
const authStore = useAuthStore();
const isAuthenticated = ref(false);

const isHomeRoute = computed(() => route.path === '/');

// Fetch user authentication status when the component is mounted
onMounted(async () => {
    try {
        await authStore.checkAuth();
        isAuthenticated.value = authStore.isAuthenticated;
    } catch (error) {
        log.error('Error checking authentication:', error);
    }
});
</script>

<style scoped>
/* New styles for horizontal navigation */
.horizontal-nav {
    margin: 20px;
}

.horizontal-nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.horizontal-nav li {
    margin: 0 15px;
}

.horizontal-nav a {
    color: #4a4a4a;
    text-decoration: none;
    font-size: 0.9rem;
}

.horizontal-nav a:hover {
    color: #007bff;
    text-decoration: underline;
}
</style>