<template>
  <div class="home-view">
    <!-- Add loading state -->
    <div v-if="isLoading" class="load-panel">
      <div class="loader"></div>
      <p>Loading...</p>
    </div>

    <!-- Show error in a more user-friendly way -->
    <div v-else-if="error" class="error-message">
      <p>{{ error }}</p>
      <button @click="reloadContent" class="button is-primary">Try Again</button>
    </div>

    <!-- Only show content when it's actually loaded -->
    <template v-else>
      <div v-if="authStatus?.isAuthenticated" class="stats">
        <div v-if="stats" class="dashboard-stats">
          <div class="stat-card">
            <h3>Total Posts</h3>
            <p>{{ stats.totalPosts }}</p>
          </div>
          <div class="stat-card">
            <h3>New Messages</h3>
            <p>{{ stats.newMessages }}</p>
          </div>
          <div class="stat-card">
            <h3>Friends</h3>
            <p>{{ stats.friendsOnline }}</p>
          </div>
        </div>
      </div>
      <div v-if="content" class="dynamic-content" v-html="content"></div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { authService } from '@/services/api/AuthService';
import log from 'loglevel';
import homeContent from '@/assets/html/homepage.html';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const stats = ref(null);
const authStatus = ref(null);
const content = ref(null);
const error = ref(null);
const isLoading = ref(true);

const loadContent = async () => {
  isLoading.value = true;
  error.value = null;
  
  try {
    // Load auth status
    authStatus.value = await authService.fetchAuthStatus();
    
    // Load content
    if (!homeContent) {
      throw new Error('Homepage content failed to load');
    }
    content.value = homeContent;
    
  } catch (err) {
    log.error('Error loading homepage:', err);
    error.value = 'Unable to load page content. Please try again.';
  } finally {
    isLoading.value = false;
  }
};

const reloadContent = () => {
  loadContent();
};

onMounted(() => {
  log.trace("Starting to mount home page.");
  loadContent();
});
</script>

<style scoped>
.load-panel {
  text-align: center;
  padding: 2rem;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  margin: 1rem auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  text-align: center;
  padding: 2rem;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin: 1rem 0;
}

.dashboard-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.stat-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
}

.stat-card h3 {
  margin-bottom: 10px;
  color: #333;
}

.stat-card p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.dynamic-content :deep(h1) {
  margin-bottom: 20px;
}

.dynamic-content :deep(h2) {
  margin-bottom: 0px;
}

.dynamic-content :deep(p) {
  margin-bottom: 15px;
}
</style>
