<template>
  <div v-if="authStore.isAuthenticated" class="left-menu-panel column is-1">
    <div class="left-menu">
      <div class="menu-item">
        <a href="/">
          <img src="@/assets/curalist-logo.svg" alt="Curalist Logo">
          <img class="app-name" src="@/assets/curalist-name.svg" alt="Curalist">
        </a>
      </div>
      <!-- Loop through filtered menu items -->
      <div class="menu-item" v-for="item in filteredMenuItems" :key="item.route">
        <router-link :to="item.route">
          <FeatherIcon :icon="item.icon" :size="30" />
          <div class="label">{{ item.label }}</div>
        </router-link>
      </div>
      <div class="toggle-menu">
        <FeatherIcon icon="chevrons-right" :size="30" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import FeatherIcon from './FeatherIcon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const authStore = useAuthStore();
const { isAuthenticated, roles } = storeToRefs(authStore);

const menuItems = ref([
  { label: 'Discover', icon: 'globe', route: '/feed', requiresAuth: false },
  { label: 'Lists', icon: 'layers', route: '/lists', requiresAuth: true },
  { label: 'Add', icon: 'plus-square', route: '/add', requiresAuth: true },
  { label: 'Search', icon: 'search', route: '/search', requiresAuth: false },
  { label: 'Curators', icon: 'users', route: '/users', requiresAuth: false },
  { label: 'Profile', icon: 'user', route: '/profile', requiresAuth: true },
  { label: 'Settings', icon: 'settings', route: '/settings', requiresAuth: true },
  { label: 'Admin', icon: 'sliders', route: '/admin', requiresAuth: true, requiresAdmin: true },
]);

// Computed property to filter menu items based on authentication status and roles
const filteredMenuItems = computed(() => {
  log.trace("filtering the menu items");
  let items = menuItems.value.filter(item => {
    // If the item requires authentication and the user is not authenticated
    if (item.requiresAuth && !isAuthenticated.value) {
      return false;
    }

    // If the item requires an admin role and the user is not an admin
    if (item.requiresAdmin && !roles.value?.includes('admin')) {
      return false;
    }

    return true;
  });

  // If the user is not authenticated, add the "Login" menu item
  if (!isAuthenticated.value) {
    items.push({
      label: 'Login',
      icon: 'log-in',
      route: '/login',
      requiresAuth: false
    });
  }

  return items;
});

// Fetch user authentication status when the component is mounted
onMounted(() => {
  authStore.checkAuth();
});
</script>

<style scoped>
.left-menu {
  background-color: var(--bg-color);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: width 0.3s;
  width: 60px;
  z-index: 1000;
}

.left-menu .app-name {
  display: none;
}

.left-menu.expanded {
  width: 140px;
}

.left-menu.expanded .logo {
  max-width: 50%;
  margin: 0 auto;
  display: block;
}

.left-menu.expanded .app-name {
  margin-top: 4px;
  display: block;
}

.left-menu-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.menu-item {
  display: flex;
  padding: 15px;
  transition: background-color 0.3s;
  vertical-align: middle;
  text-align: center;
  align-items: center;
}

.menu-item .label {
  display: none;
  font-weight: 300;
}

.left-menu.expanded .menu-item .label {
  display: inline;
}

.toggle-menu {
  cursor: pointer;
  font-size: 24px;
  padding: 15px;
  text-align: center;
}
</style>