import BaseApiService from './BaseApiService';
import log from 'loglevel';

class ListsService extends BaseApiService {
    async createList(userkey, data) {
        try {
            log.trace('Post API request to /users/xxx/lists');
            const response = await this.api.post(`users/${userkey}/lists/`, data);
            return response.data;
        } catch (error) {
            log.error('Error creating new list:', error);
            throw error;
        }
    }


    async fetchLists(page = 1, pageSize = 10, sortBy, searchText = '') {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
            });

            if (sortBy) {
                params.append('orderBy', sortBy);
            }

            const trimmedSearchText = searchText?.trim() ?? '';
            if (trimmedSearchText.length > 0) {
                const filterParam = `contains(title, '${encodeURIComponent(trimmedSearchText)}')`;
                params.append('filter', filterParam);
            }

            const response = await this.api.get('/lists', { params });
            return response.data;
        } catch (error) {
            log.error('Error fetching lists:', error);
            throw error;
        }
    }

    async get(userkey, listkey) {
        try {
            const response = await this.api.get(`/users/${userkey}/lists/${listkey}`);
            return response.data;
        } catch (error) {
            log.error('Error fetching list:', error);
            throw error;
        }
    }


    async changeAccess(userkey, listkey, accessLevel) {
        try {
            // Add the additional property to the data object
            log.trace("API toggling public status: ", userkey, listkey);
            // Add the additional property to the data object
            const data = {
                accessLevel: accessLevel
            };
            const response = await this.api.post(`users/${userkey}/lists/${listkey}/settings/access`, data);
            log.debug('API response:', response);
            return response.data;
        } catch (error) {
            console.error('Error toggling list status:', error);
            throw error;
        }
    }


    async updateList(userkey, listKey, updatedData) {
        try {
            const response = await this.api.put(`users/${userkey}/lists/${listKey}`, updatedData);
            return response.data;
        } catch (error) {
            console.error('Error updating list:', error);
            throw error;
        }
    }

    async uploadListImage(userkey, listkey, data) {
        try {
            log.trace('Post API request to /users/xxx/lists/xxx/image');
            const response = await this.api.post(`users/${userkey}/lists/${listkey}/image`, data);
            log.debug('API response:', response);
            return response.data;
        } catch (error) {
            console.error('Error creating new list:', error);
            throw error;
        }
    }

    async importList(userkey, formData) {
        const response = await this.api.post(`/users/${userkey}/lists/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }

}

export const listsService = new ListsService();
export const { createList, fetchLists, get, changeAccess, updateList, uploadListImage, importList } = listsService;