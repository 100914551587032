<template>
  <header class="top-menu" v-cloak>
    <div class="logo">
      <a href="/">
        <img src="@/assets/curalist-logo.svg" class="logo" alt="Curalist Logo">
        <img src="@/assets/curalist-name.svg" class="name" alt="Curalist">
      </a>
    </div>
    <nav class="nav-icons">
      <ul>
        <li v-for="item in filteredMenuItems" :key="item.route">
          <router-link :to="item.route">
            <FeatherIcon :icon="item.icon" :size="24" />
            <div class="nav-label" if-v="item.label">{{ item.label }}</div>
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import FeatherIcon from './FeatherIcon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();
const { isAuthenticated, roles } = storeToRefs(authStore);

const isMenuReady = ref(false);

// Menu items with metadata indicating which ones require authentication or specific roles
const menuItems = ref([
  { icon: 'globe', label: 'Discover', route: '/feed', isAuthenticated: false },
  { icon: 'log-in', label: 'Login', route: '/login', isAuthenticated: false },
  { icon: 'inbox', route: '/inbox', requiresAuth: true, requiresAdmin: false },
  { icon: 'sliders', route: '/admin', requiresAuth: true, requiresAdmin: true },
  { icon: 'settings', route: '/settings', requiresAuth: true },
]);

// Computed property to filter menu items based on authentication status and roles
const filteredMenuItems = computed(() => {
  return menuItems.value.filter(item => {
    // If the item requires authentication and the user is not authenticated
    if (item.requiresAuth && !isAuthenticated.value) {
      return false;
    }

    if (item.isAuthenticated == false && isAuthenticated.value) {
      return false;
    }

    // If the item requires an admin role and the user is not an admin
    if (item.requiresAdmin && !roles.value?.includes('admin')) {
      return false;
    }    

    return true;
  });
});

// Fetch user authentication status when the component is mounted
onMounted(() => {
  authStore.checkAuth();
  isMenuReady.value = true;
});
</script>

<style scoped>

[v-cloak] {
  display: none;
}

.top-menu .logo,
.top-menu .name {
  height: 24px;
}

.top-menu .name {
  margin-left: 8px;
}

.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.nav-icons {
  margin-right: .5em;
}

.nav-icons ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-icons li {
  margin-right: 1rem;
  /* Adjust spacing between icons */
}

.nav-icons li:last-child {
  margin-right: 0;
  /* Remove margin from the last icon */
}

.nav-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Adjust size as needed */
  height: 40px;
  /* Makes the background circular */
  transition: background-color 0.3s ease;
}

.nav-icons i {
  font-size: 1.2rem;
}

.nav-icons .nav-label {
  white-space: nowrap;
  margin-left: 0.2em;
}

.hidden-on-desktop {
  display: none;
}

</style>