import BaseApiService from './BaseApiService';
import log from 'loglevel';

class UserSettingsService extends BaseApiService {

  async fetchSettings() {
    try {
      const response = await this.api.get('/userpreferences');
      log.debug('Received response:', response.preferences)
      return response.data;
    } catch (error) {
      log.error('Error fetching settings:', error);
      throw error;
    }
  }

  async fetch() {
    return this.api.get('/userpreferences')
  }

  async update(settings) {
    return this.api.post('/userpreferences', settings)
  }

}

export const userSettingsService = new UserSettingsService();
export const { fetchSettings, fetch, update } = userSettingsService;