import axios from 'axios'
import log from 'loglevel';

class ApiService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true  // This sets withCredentials: true for all requests
    });

    // Interceptor to log all requests
    this.api.interceptors.request.use(config => {
      log.trace(`Making ${config.method.toUpperCase()} request to ${config.url}`);
      return config;
    });

    // Interceptor to log all responses
    this.api.interceptors.response.use(response => {
      log.debug('API response:', response);
      return response;
    }, error => {
      log.error('API error:', error);
      return Promise.reject(error);
    });

    this.authStatusCache = null;
    this.authStatusCacheTime = 0;
    this.CACHE_DURATION = 30000; // 30 seconds in milliseconds
    this.authStatusPromise = null;

    log.setLevel(process.env.VUE_APP_LOG_LEVEL);
  }

  async addLink(userkey, listkey, data) {
    try {
      // Add the additional property to the data object
      const linkdata = {
        ...data,
        itemType: "link"
      };
      log.trace(`Posting new list item to: 'users/${userkey}/lists/${listkey}/items'`);
      const response = await this.api.post(`users/${userkey}/lists/${listkey}/items`, linkdata);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error creating new list:', error);
      throw error;
    }
  }

  async addSublist(userkey, listkey, data) {
    try {
      // Add the additional property to the data object
      const linkdata = {
        ...data,
        itemType: "list"
      };
      log.trace(`Posting new list item to: 'users/${userkey}/lists/${listkey}/items'`);
      const response = await this.api.post(`users/${userkey}/lists/${listkey}/items`, linkdata);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error creating new list:', error);
      throw error;
    }
  }

  async createList(userkey, data) {
    try {
      log.trace('Post API request to /users/xxx/lists');
      const response = await this.api.post(`users/${userkey}/lists/`, data);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error creating new list:', error);
      throw error;
    }
  }

  async uploadListImage(userkey, listkey, data) {
    try {
      log.trace('Post API request to /users/xxx/lists/xxx/image');
      const response = await this.api.post(`users/${userkey}/lists/${listkey}/image`, data);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error creating new list:', error);
      throw error;
    }
  }

  async deleteItem(userkey, listkey, itemkey) {
    try {
      // Add the additional property to the data object
      log.trace("API deleting list item: ", userkey, listkey, itemkey);
      log.trace(`Deleting list item: 'users/${userkey}/lists/${listkey}/items/${itemkey}'`);
      const response = await this.api.delete(`users/${userkey}/lists/${listkey}/items/${itemkey}`);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error deleting list item:', error);
      throw error;
    }
  }

  async fetchDashboardData() {
    try {
      log.trace('Making API request to /api/dashboard');
      const response = await this.api.get('/dashboard');
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error in fetchDashboardData:', error);
      throw error;
    }
  }

  async fetchDocContent(docName) {
    try {
      const response = await this.api.get(`/docs/${docName}`);
      return response.data;
    } catch (error) {
      log.error('Error fetching feed items:', error);
      throw error;
    }
  }

  async fetchUrlMetadata(url) {
    try {
      const response = await this.api.get(`/urls/metadata?url=${url}`);
      return response.data;
    } catch (error) {
      log.error('Error fetching feed items:', error);
      throw error;
    }
  }

  async fetchFeed(page) {
    try {
      const response = await this.api.get(`/feed`, {
        params: {
          page
        }
      });
      return response.data.value;
    } catch (error) {
      log.error('Error fetching feed items:', error);
      throw error;
    }
  }

  async fetchLists(page, pageSize, sortBy, searchText) {
    try {
      if (page === undefined) page = 1;
      if (pageSize === undefined) pageSize = 10;
      log.debug('Fetching lists. page :', page);
      log.debug('Fetching lists. pageSize :', pageSize);
      log.debug('Fetching lists. search text :', searchText);
      log.debug('Fetching lists. sortBy :', sortBy);
      log.debug('Fetching lists. filter :', searchText);

      const params = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
      });
  
      if (sortBy) {
        params.append('orderBy', sortBy);
      }
  
      // Only add the filter if searchText is not empty or just whitespace
      const trimmedSearchText = searchText.trim();
      if (trimmedSearchText.length > 0) {
        const filterParam = `contains(title, '${encodeURIComponent(trimmedSearchText)}')`;
        params.append('filter', filterParam);
      }
  
      log.debug('Final URL parameters:', params.toString());  

      const response = await this.api.get('/lists', { params });
      return response.data;
    } catch (error) {
      log.error('Error fetching lists:', error);
      throw error;
    }
  }

  async getList(userkey, listkey) {
    try {
      const response = await this.api.get(`/users/${userkey}/lists/${listkey}`);
      return response.data;
    } catch (error) {
      log.error('Error fetching list:', error);
      throw error;
    }
  }

  //apiToggleListPublicStatus
  async setListAccess(userkey, listkey, accessLevel) {
    try {
      // Add the additional property to the data object
      log.trace("API toggling public status: ", userkey, listkey);
      // Add the additional property to the data object
      const data = {
        accessLevel: accessLevel
      };
      const response = await this.api.post(`users/${userkey}/lists/${listkey}/settings/access`, data);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error toggling list status:', error);
      throw error;
    }
  }

  async fetchSearchResults(searchQuery) {
    try {
      const response = await this.api.get(`/search?q=${searchQuery.query}&page=${searchQuery.page}&itemsPerPage=${searchQuery.itemsPerPage}`);
      return response.data;
    } catch (error) {
      log.error('Error fetching admin stats:', error);
      throw error;
    }
  }

  async fetchSettings() {
    try {
      const response = await this.api.get('/userpreferences');
      log.debug('Received response:', response.preferences)
      return response.data;
    } catch (error) {
      log.error('Error fetching settings:', error);
      throw error;
    }
  }

  async fetchStats(timeRange, userType) {
    try {
      const response = await this.api.get('/stats', {
        params: { timeRange, userType }
      });
      return response.data;
    } catch (error) {
      log.error('Error fetching admin stats:', error);
      throw error;
    }
  }

  async fetchMessages() {
    return this.api.get('/messages')
  }

  async fetchUserPreferences() {
    return this.api.get('/userpreferences')
  }

  async fetchUserProfile() {
    try {
      const response = await this.api.get('/profile');
      return response.data;
    } catch (error) {
      log.error('Error fetching user profile:', error);
      throw error;
    }
  }

  async fetchUsers() {
    try {
      const response = await this.api.get('/users');
      return response.data;
    } catch (error) {
      log.error('Error fetching users:', error);
      throw error;
    }
  }

  async saveNewItem() {
    try {
      log.trace('Post API request to /listitems');
      const response = await this.api.post('/listitems');
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error in fetchAuth:', error);
      throw error;
    }
  }

  async updateLink(userkey, listkey, itemkey, data) {
    try {
      // Add the additional property to the data object
      log.trace("API updating link: ", userkey, listkey, itemkey);
      const linkdata = {
        ...data,
        itemType: "link"
      };
      log.trace(`Posting new list item to: 'users/${userkey}/lists/${listkey}/items/${itemkey}'`);
      const response = await this.api.put(`users/${userkey}/lists/${listkey}/items/${itemkey}`, linkdata);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      console.error('Error creating new list:', error);
      throw error;
    }
  }

  async updateList(userkey, listKey, updatedData) {
    try {
      const response = await this.api.put(`users/${userkey}/lists/${listKey}`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating list:', error);
      throw error;
    }
  }

  async updateUserSettings(settings) {
    return this.api.post('/userpreferences', settings)
  }

  async submitReport(data) {
    try {
      log.trace('Submitting report to /reports');
      const response = await this.api.post('/reports', data);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error submitting report:', error);
      throw error;
    }
  }
}

// Create a single instance of ApiService
const apiService = new ApiService();

// Export the instance methods directly
export const addLink = apiService.addLink.bind(apiService);
export const addSublist = apiService.addSublist.bind(apiService);
export const deleteItem = apiService.deleteItem.bind(apiService);
export const fetchDashboardData = apiService.fetchDashboardData.bind(apiService);
export const fetchDocContent = apiService.fetchDocContent.bind(apiService);
export const fetchFeed = apiService.fetchFeed.bind(apiService);
export const fetchMessages = apiService.fetchMessages.bind(apiService);
export const fetchSettings = apiService.fetchSettings.bind(apiService);
export const fetchStats = apiService.fetchStats.bind(apiService);
export const fetchSearchResults = apiService.fetchSearchResults.bind(apiService);
export const fetchUserProfile = apiService.fetchUserProfile.bind(apiService);
export const fetchUserPreferences = apiService.fetchUserPreferences.bind(apiService);
export const fetchUsers = apiService.fetchUsers.bind(apiService);
export const fetchUrlMetadata = apiService.fetchUrlMetadata.bind(apiService);
export const saveNewItem = apiService.saveNewItem.bind(apiService);

export const createList = apiService.createList.bind(apiService);
export const fetchLists = apiService.fetchLists.bind(apiService);
export const getList = apiService.getList.bind(apiService);
export const setListAccess = apiService.setListAccess.bind(apiService);
export const updateList = apiService.updateList.bind(apiService)
export const uploadListImage = apiService.uploadListImage.bind(apiService)

export const updateLink = apiService.updateLink.bind(apiService)
export const updateUserSettings = apiService.updateUserSettings.bind(apiService)

export const submitReport = apiService.submitReport.bind(apiService);

export default apiService.api;