<template>
    <div class="doc-view">
        <div v-if="loading">Loading...</div>
        <div v-else-if="error">{{ error }}</div>
        <div v-else class="dynamic-content" v-html="content"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const route = useRoute();
const title = ref('');
const content = ref('');
const loading = ref(true);
const error = ref(null);


const getDocumentContent = async (docName) => {
    try {
        // Using Vite's dynamic import feature
        const htmlContent = await import(`../content/${docName}.html?raw`);
        return htmlContent.default;
    } catch (err) {
        throw new Error(`Failed to load document '${docName}'`);
    }
};

const loadDocContent = async (docName) => {
    loading.value = true;
    error.value = null;
    try {
        const html = await getDocumentContent(docName);

        // Use DOMParser to parse the HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Extract the title
        const titleElement = doc.querySelector('title');
        title.value = titleElement ? titleElement.textContent : docName;

        // Update the browser tab title
        document.title = `${title.value} - Curalist`;

        // Extract the body content
        const bodyElement = doc.querySelector('body');
        if (bodyElement) {
            content.value = bodyElement.innerHTML;
        } else {
            // If there's no body tag, use all the content inside the html tag
            const htmlElement = doc.querySelector('html');
            content.value = htmlElement ? htmlElement.innerHTML : html;
        }

        log.debug("Document loaded: ", docName);
        log.debug("Title: ", title.value);
    } catch (err) {
        error.value = `Failed to load document '${docName}'. Please try again later.`;
        console.error('Error fetching document:', err);
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    loadDocContent(route.params.docName);
});

watch(() => route.params.docName, (newDocName) => {
    loadDocContent(newDocName);
});
</script>

<style scoped>

</style>