import BaseApiService from './BaseApiService';
import log from 'loglevel';

class InboxService extends BaseApiService {

  async get() {
    try {
      const response = await this.api.get(`/inbox`);
      return response.data;
    } catch (error) {
      log.error('Error fetching list:', error);
      throw error;
    }
  }

  async submitLink(data) {
    try {
      log.trace('Post API request to /inbox');
      const response = await this.api.post('/inbox', data);
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error in fetchAuth:', error);
      throw error;
    }
  }

}

export const inboxService = new InboxService();
export const { get, submitLink } = inboxService;