<template>
    <div class="modal" :class="{ 'is-active': isActive }">
        <div class="modal-background" @click="cancel"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ isPublic ? 'Make List Private?' : 'Make List Public?' }}</p>
                <button class="delete" aria-label="close" @click="cancel"></button>
            </header>
            <section class="modal-card-body">
                <p class="confirm-question">Are you sure you want to make this list {{ isPublic ? 'private' : 'public'
                    }}?</p>
                <div class="content">
                    <div class="explanation-box">
                        <p><strong>When a list is {{ isPublic ? 'private' : 'public' }}:</strong></p>
                        <div v-html="explanationText"></div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div v-if="isLoading" class="loading-container">
                    <span class="loader"></span>
                    <span class="loading-text">Please wait...</span>
                </div>
                <template v-else>
                    <button class="button" @click="$emit('cancel')">Cancel</button>
                    <button class="button is-primary" @click="$emit('confirm')">
                        {{ isPublic ? 'Make Private' : 'Make Public' }}
                    </button>
                </template>
            </footer>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    isActive: Boolean,
    isPublic: Boolean,
    isLoading: Boolean,
});

const emit = defineEmits(['confirm', 'cancel']);

const explanationText = computed(() => {
    if (props.isPublic) {
        return `
        <ul>
          <li>Only you can view and edit the list</li>
          <li>The list will be removed from the feed</li>
          <li>The list won't appear in search results</li>
          <li>Users with the list link won't be able to access the list</li>
          <li>Your list's content will be hidden from other users</li>
          <li>Any existing shares or collaborations will be revoked</li>
          <li>You can make the list public again at any time</li>
        </ul>
      `;
    } else {
        return `
        <ul>
          <li>Anyone can view the list</li>
          <li>The list may appear in feed</li>
          <li>The list may appear in search results</li>
          <li>Anyone with the link can access and share the list</li>
          <li>Only you can edit the list unless you grant permissions to others</li>
          <li>Your list may gain more visibility and followers</li>
          <li>You can receive feedback and ratings from other users</li>
          <li>You can still control edit permissions separately</li>
          <li>You can make the list private again at any time</li>
        </ul>
      `;
    }
});

const cancel = () => {
    emit('cancel');
};
</script>

<style scoped>
.confirm-question {
    margin-bottom: 1em;
}

.explanation-box {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.explanation-box ul {
    margin-top: 0;
    padding-left: 20px;
}

.explanation-box li {
    margin-bottom: 8px;
}

.modal-card-foot {
    justify-content: flex-end;
    padding: 20px;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.loader {
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-text {
    color: #363636;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>