<template>
    <v-chart class="chart" :option="chartOption" autoresize />
  </template>
  
  <script>
  import { use } from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { LineChart } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
  } from "echarts/components";
  import VChart from "vue-echarts";
  import { ref, watch } from "vue";
  
  use([
    CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
  ]);
  
  export default {
    name: "LineChart",
    components: {
      VChart,
    },
    props: {
      chartData: {
        type: Object,
        required: true,
      }
    },
    setup(props) {
      const chartOption = ref({
        title: {
          text: props.chartData.title,
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: 25,
          left: 'center',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: 70,  // Increased top margin to accommodate title and legend
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [],
      });
  
      watch(
        () => props.chartData,
        (newData) => {
          if (newData && newData.xAxis && newData.series) {

            chartOption.value.title.text = newData.title;

            chartOption.value.xAxis.data = newData.xAxis;
            
            chartOption.value.series = newData.series.map((item) => ({
              name: item.name,
              type: "line",
              data: item.data,
            }));
            chartOption.value.legend.data = newData.series.map((item) => item.name);
          }
        },
        { immediate: true, deep: true }
      );
  
      return {
        chartOption,
      };
    },
  };
  </script>
  
  <style scoped>
  .chart {
    height: 300px;
  }
  </style>