<template>
    <label class="switch">
        <input type="checkbox" :checked="modelValue === 'dark'" @change="toggleTheme">
        <span class="slider round"></span>
    </label>
</template>

<script setup>
const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const toggleTheme = () => {
    emit('update:modelValue', props.modelValue === 'light' ? 'dark' : 'light');
}
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    /* Adjusted width */
    height: 24px;
    /* New height */
    margin-top: 8px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    /* Adjusted height */
    width: 18px;
    /* Adjusted width */
    left: 3px;
    /* Adjusted position */
    bottom: 3px;
    /* Adjusted position */
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    transform: translateX(20px);
    /* Adjusted translation */
}

.slider.round {
    border-radius: 24px;
    /* Adjusted to match new height */
}

.slider.round:before {
    border-radius: 50%;
}
</style>