import BaseApiService from './BaseApiService';
import log from 'loglevel';

class StatsService extends BaseApiService {

  async fetch(timeRange, userType) {
    try {
      const response = await this.api.get('/stats', {
        params: { timeRange, userType }
      });
      return response.data;
    } catch (error) {
      log.error('Error fetching admin stats:', error);
      throw error;
    }
  }

}

export const statsService = new StatsService();
export const { fetch } = statsService;