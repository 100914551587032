import BaseApiService from './BaseApiService';
import log from 'loglevel';

class SearchService extends BaseApiService {

  async fetch(searchQuery) {
    try {
      const response = await this.api.get(`/search?q=${searchQuery.query}&page=${searchQuery.page}&itemsPerPage=${searchQuery.itemsPerPage}`);
      return response.data;
    } catch (error) {
      log.error('Error fetching admin stats:', error);
      throw error;
    }
  }

}

export const searchService = new SearchService();
export const { fetch } = searchService;