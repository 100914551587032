<template>
  <div class="messages">
    <h1>Messages</h1>
    <div class="message-list">
      <div v-for="message in messages" :key="message.id" class="message-item" @click="selectMessage(message)">
        <img :src="message.avatar" :alt="message.sender" class="avatar">
        <div class="message-content">
          <h3>{{ message.sender }}</h3>
          <p>{{ message.preview }}</p>
        </div>
        <span class="message-time">{{ message.time }}</span>
      </div>
    </div>
    <div v-if="selectedMessage" class="message-detail">
      <h2>{{ selectedMessage.sender }}</h2>
      <p>{{ selectedMessage.fullMessage }}</p>
      <textarea v-model="replyText" placeholder="Type your reply..."></textarea>
      <button @click="sendReply" class="reply-button">Send Reply</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { messagesService } from '@/services/api/MessagesService';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const messages = ref([]);
const selectedMessage = ref(null);
const replyText = ref('');

onMounted(async () => {
  try {
    const data = await messagesService.fetch();
    messages.value = data;
  } catch (error) {
    log.error('Failed to fetch messages:', error);
  }
});

const selectMessage = (message) => {
  selectedMessage.value = message;
};

const sendReply = () => {
  // Implement send reply functionality
  log.debug('Sending reply:', replyText.value);
  replyText.value = '';
};
</script>

<style scoped>
.messages {
  display: flex;
  height: calc(100vh - 60px);
  /* Adjust based on your top/bottom menu height */
}

.message-list {
  flex: 1;
  overflow-y: auto;
  border-right: 1px solid #eee;
}

.message-item {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.message-item:hover {
  background-color: #f5f5f5;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.message-content {
  flex: 1;
}

.message-content h3 {
  margin: 0;
  font-size: 16px;
}

.message-content p {
  margin: 5px 0 0;
  color: #666;
  font-size: 14px;
}

.message-time {
  font-size: 12px;
  color: #999;
}

.message-detail {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message-detail h2 {
  margin-bottom: 15px;
}

textarea {
  margin-top: 20px;
  height: 100px;
  resize: vertical;
}

.reply-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reply-button:hover {
  background-color: #0056b3;
}
</style>