<template>
  <nav v-if="authStore.isAuthenticated" class="bottom-nav navbar is-fixed-bottom">
    <div class="container">
      <div class="navbar-brand">
        <!-- Loop through the filtered menu items -->
        <router-link v-for="item in filteredMenuItems" :key="item.route" :to="item.route" class="navbar-item">
          <div class="nav-item-content">
            <FeatherIcon :icon="item.icon" :size="24" />
            <span class="nav-label">{{ item.label }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import FeatherIcon from './FeatherIcon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

// Use the auth store
const authStore = useAuthStore();
const { isAuthenticated } = storeToRefs(authStore);

// Original menu items array
const menuItems = ref([
  { label: 'Discover', icon: 'globe', route: '/feed', requiresAuth: false },
  { label: 'Lists', icon: 'layers', route: '/lists', requiresAuth: true },
  { label: 'Add', icon: 'plus-square', route: '/add', requiresAuth: true },
  { label: 'Search', icon: 'search', route: '/search', requiresAuth: false },
  { label: 'Profile', icon: 'user', route: '/profile', requiresAuth: true },
]);

// Computed property to filter menu items based on authentication status
const filteredMenuItems = computed(() => {
  let items = menuItems.value.filter(item => {
    // If the item requires authentication and the user is not authenticated
    if (item.requiresAuth && !isAuthenticated.value) {
      return false;
    }
    return true;
  });

  // If the user is not authenticated, add the "Login" menu item
  if (!isAuthenticated.value) {
    items.push({
      label: 'Login',
      icon: 'log-in',
      route: '/login',
      requiresAuth: false
    });
  }

  return items;
});

// Fetch user authentication status when the component is mounted
onMounted(() => {
  authStore.checkAuth();
});
</script>

<style scoped>
.bottom-nav {
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.container {
  padding: 0;
}

.navbar-brand {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.navbar-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
}

.nav-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-label {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

/* Ensure the bottom nav is visible on mobile */
@media screen and (max-width: 768px) {
  .bottom-nav {
    display: flex !important;
  }
}

/* Hide on larger screens if needed */
@media screen and (min-width: 769px) {
  .bottom-nav {
    display: none;
  }
}
</style>