<template>
  <div class="user-list">
    <div class="search-bar">
      <input v-model="searchQuery" type="text" placeholder="Search by name or topic..." @input="filterUsers">
    </div>
    <div class="user-cards">
      <div v-for="user in filteredUsers" :key="user.id" class="user-card">
        <img :src="user.profilePhoto" :alt="user.username" class="profile-photo">
        <h3>{{ user.username }}</h3>
        <p>Lists shared: {{ user.totalListsShared }}</p>
        <div class="topics">
          <span v-for="(tag, index) in user.favoriteTopics" :key="index" class="topic">
            #{{ tag }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { fetchUsers } from '@/services/api'; // Assume this function exists to fetch user data
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const users = ref([]);
const searchQuery = ref('');

const filteredUsers = computed(() => {
  if (!searchQuery.value) return users.value;
  const query = searchQuery.value.toLowerCase();
  return users.value.filter(user =>
    user.username.toLowerCase().includes(query) ||
    user.favoriteTopics.some(topic => topic.toLowerCase().includes(query))
  );
});

const filterUsers = () => {
  // This function is called on input, but filtering is handled by the computed property
  // You could add debounce logic here if needed
};

onMounted(async () => {
  try {
    users.value = await fetchUsers();
  } catch (error) {
    log.error('Failed to fetch users:', error);
  }
});
</script>

<style scoped>
.user-list {
  padding: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.user-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.topics {
  margin-top: 10px;
}

.topics {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .user-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .user-card {
    width: 100%;
  }
}
</style>