<template>
  <div class="login-container">
    <h1>{{ isLogin ? 'Login' : 'Register' }}</h1>
    <div>{{ message }}</div>
    <div v-if="isLoading" class="loading-auth">
      <div class="loader"></div>
      <div class="loading-label">Please wait while loading ...</div>
    </div>
    <div v-else-if="authProviders.length > 0" class="login-buttons">
      <button v-for="provider in authProviders" :key="provider.provider" @click="loginWithProvider(provider.provider)"
        :class="`${provider.provider}-btn`">
        <i :class="`fa-brands fa-${getIconName(provider.provider)}`" :aria-label="`${provider.provider} Logo`"></i>
        {{ capitalizeFirstLetter(provider.provider) }}
      </button>
    </div>
    <div v-else>No authentication providers available.</div>
    <div v-if="error" class="error-message">{{ error }}</div>
    <div class="switch-mode">
      <a href="#" @click.prevent="toggleMode">
        {{ isLogin ? 'You do not have an account? Click here to register' : 'Already have an account? Click here to login' }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { authService } from '@/services/api/AuthService';

const route = useRoute();
const router = useRouter();

const error = ref(null);
const authProviders = ref([]);
const isLogin = ref(true);
const isLoading = ref(true);

const message = computed(() => {
  return isLogin.value
    ? 'Use one of the following services to login.'
    : 'Use one of the following services to register.';
});

const toggleMode = () => {
  isLogin.value = !isLogin.value;
  router.push({ path: isLogin.value ? '/login' : '/register' });
};

const fetchProviders = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await authService.fetchAuthProviders();
    authProviders.value = response;
  } catch (err) {
    console.error('Failed to fetch authentication providers:', err);
    error.value = 'Failed to load authentication options. Please try again later.';
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchProviders();
  isLogin.value = route.path === '/login';
});

watch(() => route.path, (newPath) => {
  isLogin.value = newPath === '/login';
});

const loginWithProvider = async (providerName) => {
  try {
    const provider = authProviders.value.find(p => p.provider.toLowerCase() === providerName.toLowerCase());

    if (provider) {
      window.location.href = provider.authUrl;
    } else {
      throw new Error(`${providerName} authentication provider not found`);
    }
  } catch (err) {
    error.value = `Failed to initiate ${providerName} ${isLogin.value ? 'login' : 'registration'}. Please try again.`;
    console.error(`${providerName} ${isLogin.value ? 'login' : 'registration'} error:`, err);
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getIconName = (provider) => {
  switch (provider.toLowerCase()) {
    case 'google':
      return 'google';
    case 'microsoft':
      return 'microsoft';
    default:
      return 'log-in'; // Default icon
  }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.loading-auth {
  text-align: center;
  margin: 2em;
}

.loading-auth .loader {
  text-align: center;
  margin: auto;
}

.loading-auth .loading-label {
  text-align: center;
  margin: auto;
  font-size: 1em;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

button i {
  margin-right: 10px;
}

.google-btn {
  background-color: #4285F4;
  color: white;
}

.google-btn:hover {
  background-color: #357ae8;
}

.microsoft-btn {
  background-color: #2F2F2F;
  color: white;
}

.microsoft-btn:hover {
  background-color: #1E1E1E;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.switch-mode {
  margin-top: 20px;
  text-align: center;
}

.switch-mode a {
  color: #4285F4;
  text-decoration: none;
}

.switch-mode a:hover {
  text-decoration: underline;
}


</style>