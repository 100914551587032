<template>
    <div class="card feed-item mb-5">
        <div class="card-content-wrapper">
            <div class="card-image-container">
                <a :href="'/users/' + item.userkey + '/lists/' + item.listkey">
                    <div class="card-image">
                        <figure class="image">
                            <img :src="item.imageUrl" :alt="item.title + ' theme image'" class="theme-image">
                            <div class="image-overlay"></div>
                            <h2 class="image-title">{{ item.title }}</h2>
                        </figure>
                    </div>
                </a>
                <div class="card-content">
                    <div class="media">
                        <div class="author-box">
                            <a :href="'/user/' + item.userkey">
                                <figure class="image">
                                    <img :src="item.authorImage || '/img/default-profile.png'"
                                        :alt="item.authorUsername + ' profile picture'" class="is-rounded author-image">
                                </figure>
                                <div class="username">
                                    <div class="author-prompt">Curated by</div>
                                    <div class="author-name">@{{ item.username }}</div>
                                    <span v-if="item.isVerified" class="icon has-text-info ml-2">
                                        <i class="fas fa-check-circle"></i>
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div class="description-box">
                            <a :href="'/users/' + item.userkey + '/lists/' + item.listkey">
                                <p v-if="item.description" class="description">{{ item.description }}</p>
                            </a>
                            <div v-if="item.topics && item.topics.length > 0" class="topics tags are-medium">
                                <a :href="'/search?topic=' + tag" v-for="tag in item.topics" :key="tag" class="tag">#{{ tag
                                    }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: none;" class="content">
                <a :href="'/users/' + item.userkey + '/lists/' + item.listkey">
                    <div class="columns is-mobile is-multiline mt-2">
                        <div class="column is-half-mobile">
                            <span class="icon-text">
                                <span class="icon has-text-info"><i class="fas fa-link"></i></span>
                                <span>{{ item.totalLinks }} links</span>
                            </span>
                        </div>
                        <div class="column is-half-mobile">
                            <span class="icon-text">
                                <span class="icon has-text-success"><i class="fas fa-user-friends"></i></span>
                                <span>{{ item.totalFollowers }}</span>
                            </span>
                        </div>
                        <div class="column is-half-mobile">
                            <span class="icon-text">
                                <span class="icon has-text-warning"><i class="fas fa-star"></i></span>
                                <span>{{ item.rating }} ({{ item.totalRatings }})</span>
                            </span>
                        </div>
                        <div class="column is-half-mobile">
                            <span class="icon-text">
                                <span class="icon has-text-danger"><i class="fas fa-comment"></i></span>
                                <span>{{ item.totalComments }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="list-meta">
                        <div class="is-size-7 has-text-grey">
                            Created: <time>{{ formatDate(item.createdDate) }}</time>
                        </div>
                        <div class="is-size-7 has-text-grey">
                            Last updated: <time>{{ formatDate(item.updatedDate) }}</time>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <footer class="card-footer">
            <div class="card-footer-item clickable" @click.prevent="$emit('like', item)">
                <span class="icon-text">
                    <span class="icon"><i class="far fa-heart"></i></span>
                    <span>Like</span>
                </span>
            </div>
            <div class="card-footer-item clickable" @click.prevent="$emit('bookmark', item)">
                <span class="icon-text">
                    <span class="icon"><i class="far fa-bookmark"></i></span>
                    <span>Save</span>
                </span>
            </div>
            <div class="card-footer-item clickable" @click.prevent="$emit('share', item)">
                <span class="icon-text">
                    <span class="icon"><i class="fas fa-share"></i></span>
                    <span>Share</span>
                </span>
            </div>
        </footer>
    </div>
</template>

<script setup>
defineProps({
    item: {
        type: Object,
        required: true
    }
});

defineEmits(['like', 'bookmark', 'share']);

const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
};
</script>

<style scoped>
.card {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px; /* Adjust this value as needed */
    border-radius: 8px;
    overflow: hidden;
}

.card-content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.card-image-container {
    position: relative;
}

.card-image {
    height: 10em;
    overflow: hidden;
    position: relative;
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-content {
    position: relative;
    padding: 0;    
}

.author-box {
    position: absolute;
    top: -42px;
    left: 1em;
    z-index: 1;
}

.author-box .image {
    border: 3px solid silver;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 5em;
    width: 5em;
    margin: auto;
}

.author-prompt {
    font-size: 0.6em;
    text-align: center;
    color: grey;
}

.author-name {
    font-size: 0.8em;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    color: black;
}

.description-box {
    margin-left: 8em;
}

footer.card-footer {
    background-color: #f5f5f5;
    margin: 0;
    margin-top: auto;
}

.columns {
    margin: 0.5em;
}

.list-meta {
    margin: 1em;
}

.description {
    text-decoration: none;
    color: black;
    margin: 0.5em;
}

.username {
    text-decoration: none;
    margin-top: 0.2em !important;
}

.topics {
    margin: 0.5em;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.image-title {
    position: absolute;
    top: 10px;
    left: 20px;
    right: 10px;
    color: white;
    font-size: 1.8em;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    z-index: 2;
}
</style>
