<template>
  <div class="add-new-item-view">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="welcome-section mb-5">
            <div class="content has-text-centered">
              <h2 class="title is-4">Found a link you want to remember?</h2>
              <p class="subtitle is-6">Just submit it here<br />and we'll take care of the rest!</p>
            </div>
          </div>

          <div v-if="showSuccess" class="notification is-success mt-4 success-notification">
            <button class="delete" @click="hideSuccess"></button>
            <div class="success-content">
              <p>The link saved in your inbox! 🎉</p>
              <p>We will attempt to add an appropriate title and description so that you can find it easily later.</p>
              <button class="button is-success is-light mt-2" @click="resetForm">
                Wish to save another link?
              </button>
            </div>
          </div>

          <div v-if="!showSuccess">
            <div v-if="isLoading" class="loader-container">
              <div class="loader"></div>
              <p>Saving your link...</p>
            </div>

            <div v-else class="card">
              
              <div class="card-content">
                <div class="field">
                  <label class="label">Type or paste your link below:</label>
                  <div class="control">
                    <input class="input" type="url" v-model="linkUrl" placeholder="https://example.com">
                  </div>
                </div>

                <div class="field hidden">
                  <label class="label">Link Title</label>
                  <div class="control has-icons-left">
                    <input class="input" type="text" v-model="linkTitle" placeholder="Enter a title for your link">
                    <span class="icon is-small is-left">
                      <FeatherIcon icon="type" />
                    </span>
                  </div>
                </div>

                <div class="field hidden">
                  <label class="label">Description</label>
                  <div class="control">
                    <textarea class="textarea" v-model="linkDescription"
                      placeholder="Add a description (optional)"></textarea>
                  </div>
                </div>

                <div class="field hidden">
                  <label class="label">Tags</label>
                  <div class="control has-icons-left">
                    <input class="input" type="text" v-model="linkTags" placeholder="Enter tags separated by commas">
                    <span class="icon is-small is-left">
                      <FeatherIcon icon="tag" />
                    </span>
                  </div>
                </div>

                <div class="field hidden">
                  <label class="label">Save to ...</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="selectedList">
                        <option value="existing">Existing list</option>
                        <option value="new">New list</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div v-if="selectedList === 'existing'" class="field  hidden">
                  <label class="label">Select list</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="targetList">
                        <option selected="selected" value="">Select one ...</option>
                        <option v-for="list in userLists" :key="list.id" :value="list.id">
                          {{ list.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div v-if="selectedList === 'new'" class="field hidden">
                  <label class="label">New List Name</label>
                  <div class="control has-icons-left">
                    <input class="input" type="text" v-model="newListName" placeholder="Enter a name for your new list">
                    <span class="icon is-small is-left">
                      <FeatherIcon icon="list" />
                    </span>
                  </div>
                </div>

                <div class="field mt-5">
                  <div class="control">
                    <button class="button is-primary is-fullwidth" @click="saveItem" :disabled="!isFormValid">
                      <span>Save it</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="message is-info mt-5">
            <div class="message-body content">
              <div v-if="showMoreInfo" class="mt-3">
                <p>When you submit a link, we handle the heavy lifting by fetching the title, description, and even an icon to make it easy to spot later.</p>
                <p>All your saved links go straight into your 'inbox' by default. You can always organize them into specific lists later!</p>
              </div>

              <div class="text is-small centered" @click="showMoreInfo = !showMoreInfo">
                {{ showMoreInfo ? 'Less info' : 'More info' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import FeatherIcon from '@/components/FeatherIcon.vue';
import { listsService } from '@/services/api/ListsService';
import { inboxService } from '@/services/api/InboxService';

const linkUrl = ref('');
const linkTitle = ref('');
const linkDescription = ref('');
const linkTags = ref('');
const selectedList = ref('');
const newListName = ref('');
const userLists = ref([]);
const showMoreInfo = ref(false);
const showSuccess = ref(false);
const isLoading = ref(false);

// Add URL validation function
const isValidUrl = (url) => {
  try {
    const urlObject = new URL(url);
    // Check for valid protocol
    if (urlObject.protocol !== 'http:' && urlObject.protocol !== 'https:') {
      return false;
    }
    // Check for valid domain structure (at least one dot and some characters on both sides)
    const domainParts = urlObject.hostname.split('.');
    if (domainParts.length < 2 || // Must have at least one dot
        domainParts.some(part => part.length === 0) || // No empty parts
        domainParts[domainParts.length - 1].length < 2) { // TLD must be at least 2 chars
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

// Fetch user's lists when component mounts
const fetchUserLists = async () => {
  try {
    const lists = await listsService.fetchLists();
    userLists.value = lists || []; // Ensure we always have an array
  } catch (error) {
    console.error('Error fetching lists:', error);
    userLists.value = []; // Set to empty array if fetch fails
  }
};

fetchUserLists();

const isFormValid = computed(() => {
  return linkUrl.value && isValidUrl(linkUrl.value);
});

const hideSuccess = () => {
  showSuccess.value = false;
};

const resetForm = () => {
  linkUrl.value = '';
  linkTitle.value = '';
  linkDescription.value = '';
  linkTags.value = '';
  selectedList.value = '';
  newListName.value = '';
  showSuccess.value = false;
};

const saveItem = async () => {
  try {
    isLoading.value = true;
    const newItem = {
      url: linkUrl.value,
      title: linkTitle.value,
      description: linkDescription.value,
      tags: linkTags.value ? linkTags.value.split(',').map(tag => tag.trim()) : []
    };

    await inboxService.submitLink(newItem);
    showSuccess.value = true;
  } catch (error) {
    console.error('Error saving item:', error);
    // TODO: Show error message
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.add-new-item-view {
  padding: 2rem 0;
}

.card {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.text.is-small {
  font-size: small;
}
.text.centered {
  text-align: center;
  width: 100%;
  margin: 1em auto;
}

.button.is-primary {
  transition: all 0.3s ease;
}

.button.is-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  text-align: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3273dc;
  animation: spin 1s linear infinite;
  margin-bottom: 1em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-notification {
  text-align: center;
}

.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>