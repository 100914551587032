import BaseApiService from './BaseApiService';
import log from 'loglevel';

class ListItemsService extends BaseApiService {

    async addLink(userkey, listkey, data) {
        try {
            // Add the additional property to the data object
            const linkdata = {
                ...data,
                itemType: "link"
            };
            log.trace(`Posting new list item to: 'users/${userkey}/lists/${listkey}/items'`);
            const response = await this.api.post(`users/${userkey}/lists/${listkey}/items`, linkdata);
            log.debug('API response:', response);
            return response.data;
        } catch (error) {
            console.error('Error creating new list:', error);
            throw error;
        }
    }

    async addSublist(userkey, listkey, data) {
        try {
            // Add the additional property to the data object
            const linkdata = {
                ...data,
                itemType: "list"
            };
            log.trace(`Posting new list item to: 'users/${userkey}/lists/${listkey}/items'`);
            const response = await this.api.post(`users/${userkey}/lists/${listkey}/items`, linkdata);
            log.debug('API response:', response);
            return response.data;
        } catch (error) {
            console.error('Error creating new list:', error);
            throw error;
        }
    }

    async erase(userkey, listkey, itemkey) {
        try {
            // Add the additional property to the data object
            log.trace("API deleting list item: ", userkey, listkey, itemkey);
            log.trace(`Deleting list item: 'users/${userkey}/lists/${listkey}/items/${itemkey}'`);
            const response = await this.api.delete(`users/${userkey}/lists/${listkey}/items/${itemkey}`);
            log.debug('API response:', response);
            return response.data;
        } catch (error) {
            console.error('Error deleting list item:', error);
            throw error;
        }
    }

    async saveNewItem() {
        try {
          log.trace('Post API request to /listitems');
          const response = await this.api.post('/listitems');
          log.debug('API response:', response);
          return response.data;
        } catch (error) {
          log.error('Error in fetchAuth:', error);
          throw error;
        }
      }
    
      async updateLink(userkey, listkey, itemkey, data) {
        try {
          // Add the additional property to the data object
          log.trace("API updating link: ", userkey, listkey, itemkey);
          const linkdata = {
            ...data,
            itemType: "link"
          };
          log.trace(`Posting new list item to: 'users/${userkey}/lists/${listkey}/items/${itemkey}'`);
          const response = await this.api.put(`users/${userkey}/lists/${listkey}/items/${itemkey}`, linkdata);
          log.debug('API response:', response);
          return response.data;
        } catch (error) {
          console.error('Error creating new list:', error);
          throw error;
        }
      }

}

export const listItemsService = new ListItemsService();
export const { addLink, addSublist, erase, saveNewItem, updateLink } = listItemsService;