import BaseApiService from './BaseApiService';
import log from 'loglevel';

class UserProfileService extends BaseApiService {

  async fetch() {
    try {
      const response = await this.api.get('/profile');
      return response.data;
    } catch (error) {
      log.error('Error fetching user profile:', error);
      throw error;
    }
  }

}

export const userProfileService = new UserProfileService();
export const { fetch } = userProfileService;